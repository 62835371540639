import styled from "styled-components";
import { motion } from "framer-motion";

export const CardDisplayLargeContainer = styled(motion.div)`
    align-items: center;
    background: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    height: 352px;
    justify-content: center;
    position: relative;
    width: 256px;
    transition: transform 0.1s ease;
`;
export const ImgContainer = styled.div`
    align-items: center;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    img {
        opacity: 0.4;
        width: 40%;
    }
`;
export const LeftOverlayContent = styled.div`
    align-items: end;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    right: 100%;
    transform: translateX(-16px);
    width: 500px;

    h1 {
        font-family: 'Roboto Condensed';
        font-size: 20px;
        opacity: 0.8;
    }
`;
export const RightOverlayContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    left: 100%;
    transform: translateX(16px);
    width: 500px;
`;