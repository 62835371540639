import _ from 'lodash';
import { SHOWCASE_ACTION_TYPES } from './showcase.types';

export const setPreselectedCardId = (cardId) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_ID, payload: cardId }
}
export const setPreselectedCardType = (cardType) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_TYPE, payload: cardType }
}
export const setSelectedCardId = (cardId) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_SELECTED_CARD_ID, payload: cardId }
}
export const setNewSelectionCardData = (data) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_NEW_SELECTION_CARD_DATA, payload: data }
}
export const setIsViewingNewSelection = (status) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_IS_VIEWING_NEW_SELECTION, payload: status }
}
export const setIsEditingCard = (status) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_IS_EDITING_CARD, payload: status }
}
export const setIsAddingCardImgs = (status) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_IS_ADDING_CARD_IMGS, payload: status }
}
export const setCardImgsWereAdded = (status) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_CARD_IMGS_WERE_ADDED, payload: status }
}
export const setDreamTeam = (cardData, sport) => {
    switch (sport) {
        case "dreamTeamCardData_MLB":
            return { type: SHOWCASE_ACTION_TYPES.SET_DREAM_TEAM_MLB, payload: cardData };
        default:
            return null;
    }
}

export const updateReduxPositionData = (positionId, updatedData) => {
    return { type: SHOWCASE_ACTION_TYPES.UPDATE_DREAM_TEAM_POSITION, payload: { positionId, updatedData }}
  };

export const removeShowcaseCard = (dreamTeamData, posId) => {
    const newDreamTeamData_MLB = _.cloneDeep(dreamTeamData);

    newDreamTeamData_MLB[posId] = {
        ...newDreamTeamData_MLB[posId],
        name: null,
        team: null,
        cardNumber: null,
        cardAttributes: null,
        cardPath: null,
        cardParallel: null
    }

    return {
        type: SHOWCASE_ACTION_TYPES.SET_DREAM_TEAM_MLB,
        payload: newDreamTeamData_MLB
    };
}