import { useDispatch, useSelector } from 'react-redux';
import { setPreselectedCardId, setPreselectedCardType } from '../../../../store/showcase/showcase.action';
import { selectPreSelectedCardId, selectPreSelectedCardType } from '../../../../store/showcase/showcase.selector';

// styles
import {
    BullpenDisplayContainer,
    Bullpen,
    BullpenCardsContainer,
    CardGroupContainer
} from './BullpenDisplay.styles';

// components
import CardDisplay from '../card-display/CardDisplay';
import CardOptions from '../card-options/CardOptions';
import SecondaryTitle from '../../../../components/widgets/components/secondary-title/SecondaryTitle';

// data
const dreamTeamRotationPositions_MLB = {
    "rotation-SP1": {
        id: "rotation-SP1",
        label: "SP"
    },
    "rotation-SP2": {
        id: "rotation-SP2",
        label: "SP"
    },
    "rotation-SP3": {
        id: "rotation-SP3",
        label: "SP"
    },
    "rotation-SP4": {
        id: "rotation-SP4",
        label: "SP"
    },
    "rotation-SP5": {
        id: "rotation-SP5",
        label: "SP"
    }
};
const dreamTeamBullpenPositions_MLB = {
    "bullpen-LRP1": {
        id: "bullpen-LRP1",
        label: "LRP"
    },
    "bullpen-LRP2": {
        id: "bullpen-LRP2",
        label: "LRP"
    },
    "bullpen-MRP1": {
        id: "bullpen-MRP1",
        label: "MRP"
    },
    "bullpen-MRP2": {
        id: "bullpen-MRP2",
        label: "MRP"
    },
    "bullpen-SU1": {
        id: "bullpen-SU1",
        label: "SU"
    },
    "bullpen-SU2": {
        id: "bullpen-SU2",
        label: "SU"
    },
    "bullpen-CL": {
        id: "bullpen-CL",
        label: "CL"
    }
};

export default function BullpenDisplay() {
    const dispatch = useDispatch();
    const preSelectedCardId = useSelector(selectPreSelectedCardId);
    const preSelectedCardType = useSelector(selectPreSelectedCardType);

    const handleCardPreselection = (cardType, id) => {
        dispatch(setPreselectedCardId(id));
        dispatch(setPreselectedCardType(cardType));
    }

    return (
        <BullpenDisplayContainer>
            {preSelectedCardType !== "field" ?
                <Bullpen>
                    <CardGroupContainer>
                        <SecondaryTitle text={"STARTING ROTATION"} />
                        <BullpenCardsContainer>
                            {Object.entries(dreamTeamRotationPositions_MLB).map(([id, { label }]) => (
                                <CardDisplay
                                    key={id}
                                    id={id}
                                    positionLabel={label}
                                    isActive={preSelectedCardId === id}
                                    onClick={() => handleCardPreselection("bullpen", id)}
                                />
                            ))}
                        </BullpenCardsContainer>
                    </CardGroupContainer>
                    <CardGroupContainer>
                        <SecondaryTitle text={"BULLPEN"} />
                        <BullpenCardsContainer>
                            {Object.entries(dreamTeamBullpenPositions_MLB).map(([id, { label }]) => (
                                <CardDisplay
                                    key={id}
                                    id={id}
                                    positionLabel={label}
                                    isActive={preSelectedCardId === id}
                                    onClick={() => handleCardPreselection("bullpen", id)}
                                />
                            ))}
                        </BullpenCardsContainer>
                    </CardGroupContainer>
                </Bullpen>
                :
                <CardOptions />
            }
        </BullpenDisplayContainer>
    )
}
