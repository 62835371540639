import styled from "styled-components";

export const CardPanelContainer = styled.div`

    h2 {
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
        height: 100%;
        left: 0;
        padding: 16px;
        position: absolute;
        top: 0;
        width: 100%;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
`
export const CardCountContainer = styled.div`
    align-items: center;
    bottom: 8px;
    display: flex;
    gap: 8px;
    height: 40px;
    position: absolute;
    right: 24px;
    width: fit-content;

    h5 {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    img {
        height: 16px;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
`