import styled from "styled-components";

export const AddCardPageContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 48px;
    width: 100%;
`
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`
export const RecentsContainer = styled.div`
    h2 {
        color: #FFFFFF;
    }
`
export const PanelRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`
export const PanelRowTitle = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;

    h4 {
        background-color: rgba(255,255,255,0.2);
        border: 2px solid rgba(255,255,255,0.2);
        border-radius: 100px;
        color: #FFFFFF;
        cursor: pointer;
        padding: 4px 12px;

        &:hover {
            background-color: #FFFFFF;
            color: #000000;
        }
    }
`
export const HeaderText = styled.h2`
    color: #FFFFFF;        
`
export const QueuedCardsContainer = styled.div`
  position: ${(props) => (props.$fixed ? 'fixed' : 'absolute')};
  right: 48px;
  top: ${(props) => (props.$fixed ? '48px' : 'auto')};
`;
export const OutletContainer = styled.div`
    width: ${(props) => props.$width};
`