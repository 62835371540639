// styles
import {
    HeaderWidgetContainer
} from './HeaderWidget.styles';

export default function HeaderWidget({ children }) {
  return (
    <HeaderWidgetContainer className='widget-bg'>
        { children }
    </HeaderWidgetContainer>
  )
}
