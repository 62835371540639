import { useSelector } from 'react-redux';
import { selectIsAddingCardImgs, selectNewSelectionCardData } from '../../../../store/showcase/showcase.selector';

// styles
import {
    ShowcaseSelectionViewContainer,
} from './ShowcaseSelectionView.styles';

// components
import CardDisplayLarge from '../card-display-large/CardDisplayLarge';
import CardImgUploader from '../card-img-uploader/CardImgUploader';
import CardOptions from '../card-display-large/components/card-options/CardOptions';

export default function ShowcaseSelectionView({ cardData }) {
    const isAddingCardImgs = useSelector(selectIsAddingCardImgs);
    const newSelectionCardData = useSelector(selectNewSelectionCardData);

    return (
        <ShowcaseSelectionViewContainer>
            <CardOptions />
            {!isAddingCardImgs ?
                <CardDisplayLarge size={"L"} posIsDisplayed={false} cardData={newSelectionCardData.name.length === 0 ? cardData : newSelectionCardData} />
                :
                <CardImgUploader />
            }
        </ShowcaseSelectionViewContainer>

    );
}