import styled from "styled-components";
import { motion } from "framer-motion";

export const FieldDisplayContainer = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    padding: 72px;
    padding-left: 0px;
    position: relative;
    width: 60%;
`
export const OutlineContainer = styled.div`
    width: 100%;   
`
export const FieldImg = styled(motion.img)`
    opacity: 0.2;
    width: 100%;
`
export const PlacedPlayerCard = styled(motion.div)`
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
`