// styles
import {
    CardShopContainer
} from "./CardShop.styles";

// components
import HeaderWidget from "../../components/widgets/header-widget/HeaderWidget";
import Title from '../../components/widgets/components/title/Title';

export default function CardShop() {
    return (
        <CardShopContainer>
            <HeaderWidget>
                <Title title={"CARD SHOP"} subtitle={"16 ACTIVE LISTINGS"} btnPath={{ title: "+ NEW LISTING", path: "" }} />
            </HeaderWidget>
        </CardShopContainer>
    )
}
