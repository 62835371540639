import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDreamTeamCardData_MLB, selectSelectedCardId } from '../../../../store/showcase/showcase.selector';
import { setNewSelectionCardData } from '../../../../store/showcase/showcase.action';

// styles
import {
    DreamTeamContainer,
    TitleContainer,
    ContentContainer
} from './DreamTeam.styles';

// components
import BullpenDisplay from '../bullpen-display/BullpenDisplay';
import FieldDisplay from '../field-display/FieldDisplay';
import ShowcaseSelectionView from '../showcase-selection-view/ShowcaseSelectionView';
import Title from '../../../../components/widgets/components/title/Title';

export default function DreamTeam() {
    const dispatch = useDispatch();
    const selectedCardId = useSelector(selectSelectedCardId);
    const dreamTeamCardData_MLB = useSelector(selectDreamTeamCardData_MLB);

    useEffect(() => {
        const newSelectionCardData = {
            name: [],
            cardPath: [],
            cardParallel: {
                cardColor: '',
                cardParallelName: '',
                cardPrintNumber: null,
                cardSupply: 0,
            }
        };
        dispatch(setNewSelectionCardData(newSelectionCardData));
    }, [dispatch])

    return (
        <DreamTeamContainer>
            {!selectedCardId &&
                <TitleContainer>
                    <Title text={"SHOWCASE"} />
                </TitleContainer>
            }
            {selectedCardId === null ?
                <ContentContainer>
                    <FieldDisplay />
                    <BullpenDisplay />
                </ContentContainer>
                :
                <ContentContainer>
                    <ShowcaseSelectionView cardData={dreamTeamCardData_MLB[selectedCardId]} />
                </ContentContainer>
            }
        </DreamTeamContainer>
    )
}
