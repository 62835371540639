import styled from "styled-components";
import { motion } from "framer-motion";

export const CardOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: center;
    width: 100%;
`
export const BtnContainer = styled(motion.div)`
    width: 240px;
`