import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// styles
import {
    CardImgUploaderContainer,
    CardImgContent,
    CardImgDisplay,
    ImgContainer,
    OrientationToggle,
} from './CardImgUploader.styles';
import { setCardImgsWereAdded } from '../../../../store/showcase/showcase.action';


export default function CardImgUploader() {
    const dispatch = useDispatch();
    const [frontImg, setFrontImg] = useState(null); // State for the front image
    const [backImg, setBackImg] = useState(null); // State for the back image
    const [frontOrientation, setFrontOrientation] = useState("vertical"); // Orientation for front image
    const [backOrientation, setBackOrientation] = useState("vertical"); // Orientation for back image

    useEffect(() => {
        if ((frontImg !== null) && (backImg !==null)) {
            dispatch(setCardImgsWereAdded(true));
        } else {
            dispatch(setCardImgsWereAdded(false));
        }
    }, [dispatch, frontImg, backImg])

    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file)); // Create a preview URL for the uploaded image
        }
    };

    return (
        <CardImgUploaderContainer>
            <CardImgContent>
                <CardImgDisplay orientation={frontOrientation} hasImg={frontImg !== null}>
                    <ImgContainer orientation={frontOrientation}>
                        {frontImg && <img src={frontImg} alt="Front of the card" />}
                    </ImgContainer>
                </CardImgDisplay>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, setFrontImg)}
                />
                <OrientationToggle>
                    <label>
                        <input
                            type="radio"
                            name="frontOrientation"
                            value="vertical"
                            checked={frontOrientation === "vertical"}
                            onChange={() => setFrontOrientation("vertical")}
                        />
                        Vertical
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="frontOrientation"
                            value="horizontal"
                            checked={frontOrientation === "horizontal"}
                            onChange={() => setFrontOrientation("horizontal")}
                        />
                        Horizontal
                    </label>
                </OrientationToggle>
            </CardImgContent>

            <CardImgContent>
                <CardImgDisplay orientation={backOrientation} hasImg={backImg !== null}>
                    <ImgContainer orientation={backOrientation}>
                        {backImg && <img src={backImg} alt="Back of the card" />}
                    </ImgContainer>
                </CardImgDisplay>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, setBackImg)}
                />
                <OrientationToggle>
                    <label>
                        <input
                            type="radio"
                            name="backOrientation"
                            value="vertical"
                            checked={backOrientation === "vertical"}
                            onChange={() => setBackOrientation("vertical")}
                        />
                        Vertical
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="backOrientation"
                            value="horizontal"
                            checked={backOrientation === "horizontal"}
                            onChange={() => setBackOrientation("horizontal")}
                        />
                        Horizontal
                    </label>
                </OrientationToggle>
            </CardImgContent>
        </CardImgUploaderContainer>
    );
}