import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectDreamTeamCardData_MLB } from '../../../../store/showcase/showcase.selector';

// styles
import {
    CardDisplayContainer,
    ImgContainer,
    PositionContainer
} from './CardDisplay.styles';

// assets
import pullVaultIcon from '../../../../assets/system/cardvault-symbol.png';

// components
import CardAttribute from '../../../../components/card-attribute/CardAttribute';

export default function CardDisplay({ id, isActive, positionLabel, onClick }) {
    const dreamTeamCardData_MLB = useSelector(selectDreamTeamCardData_MLB);

    const cardData = dreamTeamCardData_MLB?.[id] || null; // Fallback to an empty object

    return (
        <CardDisplayContainer
            onClick={onClick}
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 240, damping: 20 }}
        >
            <ImgContainer
                $isActive={isActive}
                $hasAssignedCard={!!cardData?.name}
            >
                <img src={pullVaultIcon} alt="no card" />
            </ImgContainer>
            <PositionContainer>
                <CardAttribute text={positionLabel} />
            </PositionContainer>
        </CardDisplayContainer>
    );
}

CardDisplay.propTypes = {
    id: PropTypes.string.isRequired,
    positionLabel: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};