import { useDispatch, useSelector } from 'react-redux';
import { setPreselectedCardId, setPreselectedCardType } from '../../../../store/showcase/showcase.action';
import { selectPreSelectedCardId, selectPreSelectedCardType } from '../../../../store/showcase/showcase.selector';
import { fadeInUp } from '../../../../animations/Animations';

// styles
import {
    FieldDisplayContainer,
    OutlineContainer,
    FieldImg,
    PlacedPlayerCard
} from './FieldDisplay.styles';

// assets
import fieldOutline from '../../../../assets/system/diamond-outline.svg';

// components
import CardDisplay from '../card-display/CardDisplay';
import CardOptions from '../card-options/CardOptions';

// data
const dreamTeamFieldPositions_MLB = {
    "field-DH": {
        id: "field-DH",
        label: "DH",
        position: { top: '75%', left: '57%' }
    },
    "field-P": {
        id: "field-P",
        label: "P",
        position: { top: '54%', left: '42%' }
    },
    "field-C": {
        id: "field-C",
        label: "C",
        position: { top: '75%', left: '42%' }
    },
    "field-1B": {
        id: "field-1B",
        label: "1B",
        position: { top: '54%', left: '65%' }
    },
    "field-2B": {
        id: "field-2B",
        label: "2B",
        position: { top: '42%', left: '55%' }
    },
    "field-3B": {
        id: "field-3B",
        label: "3B",
        position: { top: '54%', left: '21%' }
    },
    "field-SS": {
        id: "field-SS",
        label: "SS",
        position: { top: '42%', left: '31%' }
    },
    "field-LF": {
        id: "field-LF",
        label: "LF",
        position: { top: '30%', left: '17%' }
    },
    "field-CF": {
        id: "field-CF",
        label: "CF",
        position: { top: '25%', left: '42%' }
    },
    "field-RF": {
        id: "field-RF",
        label: "RF",
        position: { top: '30%', left: '69%' }
    }
};

export default function FieldDisplay() {
    const dispatch = useDispatch();
    const preSelectedCardId = useSelector(selectPreSelectedCardId);
    const preSelectedCardType = useSelector(selectPreSelectedCardType);

    const handleCardPreselection = (cardType, id) => {
        dispatch(setPreselectedCardId(id));
        dispatch(setPreselectedCardType(cardType));
    };

    return (
        <FieldDisplayContainer>
            {preSelectedCardType !== "bullpen" ?
                <>
                    <OutlineContainer>
                        <FieldImg
                            src={fieldOutline}
                            alt='field outline'
                            initial="hidden"
                            animate="transparent"
                            variants={fadeInUp}
                            custom={0.2}
                        />
                    </OutlineContainer>
                    {Object.values(dreamTeamFieldPositions_MLB).map(({ id, label, position }) => (
                        <PlacedPlayerCard
                            key={id}
                            style={{ top: position.top, left: position.left }}
                        >
                            <CardDisplay
                                key={id}
                                id={id}
                                positionLabel={label}
                                isActive={preSelectedCardId === id}
                                onClick={() => handleCardPreselection("field", id)}
                            />
                        </PlacedPlayerCard>
                    ))}
                </>
                :
                <CardOptions />
            }
        </FieldDisplayContainer>
    )
}
