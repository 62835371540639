import { useNavigate } from 'react-router-dom';

// styles
import { TertiaryBtnContainer } from './TertiaryBtn.styles.js';

// assets
import chevronIcon from '../../../assets/system/icons/chevron-icon.svg';

export default function TertiaryBtn({title, route=null, disabled=false, backIcon=false}) {
  const navigate = useNavigate()

  const handleNavigate = (r) => {
    if (r) {
      navigate(r);
    } else  {
      return;
    }
  }

  return (
    <TertiaryBtnContainer onClick={() => handleNavigate(route)} $isDisabled={disabled}>
      {backIcon &&
        <img src={chevronIcon} alt='back button' />
      }
      {title}
    </TertiaryBtnContainer>
  )
}