import _ from 'lodash';
import { CARD_ACTION_TYPES } from '../card/card.types';

export const setCardData = (cardData) => {
  return { type: CARD_ACTION_TYPES.SET_CARD_DATA, payload: cardData }
}
export const setChecklistData = (checklistData) => {
  return { type: CARD_ACTION_TYPES.SET_CHECKLIST_DATA, payload: checklistData }
}

export const setCardParallels = (cardParallels) => {
  return { type: CARD_ACTION_TYPES.SET_PARALLELS_ROUTES, payload: cardParallels }
}

export const addCardToQueue = (
  queuedCards,
  sport,
  year,
  manufacturer,
  series,
  cardType,
  cardTypeCategory,
  card,
  qty
) => {
  const cardNumber = card.cardNumber;
  const newQueuedCards = _.cloneDeep(queuedCards);

  console.log("addCardToQueue: passing Card as paramater, card: ", card)
  const { cardColor, cardParallelName, cardPrintNumber, cardSupply, cardQty } = card.collectedCards[0];

  // Initialize totalCardCount if not present
  if (!newQueuedCards.totalCardCount) {
    newQueuedCards.totalCardCount = 0;
  }

  // Initialize nested structures as needed
  if (!newQueuedCards[sport]) {
    newQueuedCards[sport] = { totalCardCount: 0 };
  }
  if (!newQueuedCards[sport][year]) {
    newQueuedCards[sport][year] = { totalCardCount: 0 };
  }
  if (!newQueuedCards[sport][year][manufacturer]) {
    newQueuedCards[sport][year][manufacturer] = { totalCardCount: 0 };
  }
  if (!newQueuedCards[sport][year][manufacturer][series]) {
    newQueuedCards[sport][year][manufacturer][series] = { totalCardCount: 0 };
  }
  if (!newQueuedCards[sport][year][manufacturer][series][cardType]) {
    newQueuedCards[sport][year][manufacturer][series][cardType] = { totalCardCount: 0 };
  }
  if (!newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory]) {
    newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory] = { totalCardCount: 0 };
  }

  // Check if the card already exists in the queue
  const existingCard = newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory][cardNumber];

  if (existingCard) {
    console.log("addCardToQueue: Card already exists in the Queue, existingCard: ", existingCard)
    // Check if the specific variation exists in the collectedCards array
    const parallelIndex = existingCard.collectedCards.findIndex(v => {
      const concatenatedExisting = `${v.cardParallelName || ''}${v.cardPrintNumber || ''}${v.cardSupply || ''}`;
      const concatenatedCard = `${cardParallelName || ''}${cardPrintNumber || ''}${cardSupply || ''}`;

      return concatenatedExisting === concatenatedCard;
    });

    if (parallelIndex !== -1) {
      console.log("addCardToQueue: Parallel exists in collectedCards");
      // If the variation exists, increment the quantity
      const newQty = existingCard.collectedCards[parallelIndex].cardQty + qty
      if (newQty <= 0) {
        existingCard.collectedCards.splice(parallelIndex, 1);
      } else {
        existingCard.collectedCards[parallelIndex].cardQty += qty;
      }
    } else {
      console.log("addCardToQueue: Parallel does not exist in collectedCards")
      // If the variation doesn't exist, add it with the given quantity
      existingCard.collectedCards.push(
        {
          cardParallelName: cardParallelName,
          cardColor: cardColor,
          cardQty: cardQty,
          cardPrintNumber: cardPrintNumber,
          cardSupply: cardSupply
        }
      );
    }

    // Also increment the overall card quantity
    existingCard.quantity += qty;

    if (existingCard.quantity <= 0) {
      delete newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory][cardNumber];
    }

  } else {
    console.log("addCardToQueue: Card does not exist in the Queue")
    const checklistPath = `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}/${cardNumber}`;

    // If the card doesn't exist, add it with the variation and quantity
    newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory][cardNumber] = {
      ...card,
      quantity: qty,
      collectedCards: [
        {
          cardParallelName: cardParallelName,
          cardColor: cardColor,
          cardQty: cardQty,
          cardPrintNumber: cardPrintNumber,
          cardSupply: cardSupply
        }
      ],
      checklistPath: checklistPath
    };
  }

  // Increment the total card count at each level
  newQueuedCards.totalCardCount += qty;
  newQueuedCards[sport].totalCardCount += qty;
  newQueuedCards[sport][year].totalCardCount += qty;
  newQueuedCards[sport][year][manufacturer].totalCardCount += qty;
  newQueuedCards[sport][year][manufacturer][series].totalCardCount += qty;
  newQueuedCards[sport][year][manufacturer][series][cardType].totalCardCount += qty;
  newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory].totalCardCount += qty;

  if (newQueuedCards[sport].totalCardCount <= 0) {
    delete newQueuedCards[sport];
  } else
    if (newQueuedCards[sport][year].totalCardCount <= 0) {
      delete newQueuedCards[sport][year];
    } else
      if (newQueuedCards[sport][year][manufacturer].totalCardCount <= 0) {
        delete newQueuedCards[sport][year][manufacturer];
      } else
        if (newQueuedCards[sport][year][manufacturer][series].totalCardCount <= 0) {
          delete newQueuedCards[sport][year][manufacturer][series];
        } else
          if (newQueuedCards[sport][year][manufacturer][series][cardType].totalCardCount.totalCardCount <= 0) {
            delete newQueuedCards[sport][year][manufacturer][series][cardType];
          } else
            if (newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory].totalCardCount <= 0) {
              delete newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory]
            }

  return { type: CARD_ACTION_TYPES.SET_QUEUED_CARDS, payload: newQueuedCards };
};

export const removeCardFromQueue = (queuedCards, card, parallelToRemove) => {
  const { sport, year, manufacturer, series, cardType, cardTypeCategory, card: { cardNumber } } = card;

  // Create a deep copy of the queuedCards object to avoid direct mutation
  const newQueuedCards = _.cloneDeep(queuedCards);

  // Get the existing card from the queue
  const existingCard = newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory][cardNumber];

  if (existingCard) {
    const parallelIndex = existingCard.collectedCards.findIndex(parallel => parallel.cardParallelName === parallelToRemove);

    if (parallelIndex !== -1) {
      const qtyToRemove = existingCard.collectedCards[parallelIndex].cardQty;

      // Remove the specific variation
      existingCard.collectedCards.splice(parallelIndex, 1);

      // Adjust the total quantity of the card
      existingCard.quantity -= qtyToRemove;

      // If no variations are left, remove the card entirely
      if (existingCard.collectedCards.length === 0 || existingCard.quantity <= 0) {
        delete newQueuedCards[sport][year][manufacturer][series][cardType][cardTypeCategory][cardNumber];
      }

      // Adjust the total counts
      adjustTotalCounts(newQueuedCards, sport, year, manufacturer, series, cardType, cardTypeCategory, qtyToRemove);
    }
  }

  return { type: CARD_ACTION_TYPES.SET_QUEUED_CARDS, payload: newQueuedCards };
};

// Adjust total counts function to update counts and remove empty levels
const adjustTotalCounts = (newQueuedCards, sport, year, manufacturer, series, cardType, cardTypeCategory, qty) => {
  const levels = [
    { level: newQueuedCards[sport]?.[year]?.[manufacturer]?.[series]?.[cardType]?.[cardTypeCategory], key: cardTypeCategory },
    { level: newQueuedCards[sport]?.[year]?.[manufacturer]?.[series]?.[cardType], key: cardType },
    { level: newQueuedCards[sport]?.[year]?.[manufacturer]?.[series], key: series },
    { level: newQueuedCards[sport]?.[year]?.[manufacturer], key: manufacturer },
    { level: newQueuedCards[sport]?.[year], key: year },
    { level: newQueuedCards[sport], key: sport }
  ];

  levels.forEach(({ level, key }, idx) => {
    if (level) {
      if (level.totalCardCount === qty) {
        // If the total count matches the quantity, remove the entire level
        delete levels[idx + 1]?.level[key];
      } else {
        // Otherwise, subtract the quantity from the total count
        level.totalCardCount -= qty;
      }
    }
  });

  // Decrement the total card count in the entire collection by the card quantity
  newQueuedCards.totalCardCount -= qty;

  // Clean up any empty levels that may remain
  levels.reverse().forEach(({ level, key }, idx) => {
    if (level && Object.keys(level).length === 0) {
      if (idx < levels.length - 1) {
        delete levels[idx + 1]?.level[key];
      }
    }
  });

  // Ensure the sport level is removed if empty
  if (newQueuedCards[sport] && Object.keys(newQueuedCards[sport]).length === 1) {
    delete newQueuedCards[sport];
  }
};


export const addCardToQueueDisplay = (
  queuedCardsDisplay,
  sport,
  year,
  manufacturer,
  series,
  cardType,
  cardTypeCategory,
  card,
  qty
) => {

  console.log("addCardToQueueDisplay: passed in card variable, card: ", card)
  console.log("addCardToQueueDisplay: queuedCardsDisplay", queuedCardsDisplay)

  function compareNameArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((name, index) => name === arr2[index]); // Strict comparison of each element
  }

  // Find all possible matches that share the same sport, year, etc. and cardNumber.
  const possibleCardMatches = queuedCardsDisplay.filter(
    (queuedCard) =>
      queuedCard.sport === sport &&
      queuedCard.year === year &&
      queuedCard.manufacturer === manufacturer &&
      queuedCard.series === series &&
      queuedCard.cardType === cardType &&
      queuedCard.cardTypeCategory === cardTypeCategory &&
      queuedCard.card.cardNumber === card.cardNumber && // Ensure exact match of cardNumber
      compareNameArrays(queuedCard.card.name, card.name));

  console.log("possibleCardMatches: ", possibleCardMatches)

  let parallelIndex = -1;
  let matchedCardIndex = -1;

  // Loop through possible matches to find the correct parallelIndex
  possibleCardMatches.some((queuedCard, existingCardIndex) => {
    parallelIndex = queuedCard.card.collectedCards.findIndex(v => {
      const concatenatedExisting = `${v.cardParallelName || ''}${v.cardPrintNumber || ''}${v.cardSupply || ''}`;
      const concatenatedCard = `${card.collectedCards[0].cardParallelName || ''}${card.collectedCards[0].cardPrintNumber || ''}${card.collectedCards[0].cardSupply || ''}`;
      return concatenatedExisting === concatenatedCard;
    });

    // If a match is found, store the existingCardIndex and break out of the loop
    if (parallelIndex !== -1) {
      matchedCardIndex = existingCardIndex;  // Save the index of the matched card
      console.log(`Match found in queued card at index: ${existingCardIndex} with parallel index: ${parallelIndex}`);
      return true; // Stop the loop once a match is found
    }

    return false;  // Continue loop if no match is found
  });

  console.log("parallelIndex: ", parallelIndex)

  let newQueuedCardsDisplay;

  if (parallelIndex !== -1) {
    const existingCard = possibleCardMatches[matchedCardIndex];
    const newQuantity = existingCard.card.collectedCards[parallelIndex].cardQty + qty;

    if (newQuantity <= 0) {
      // If the new quantity is less than or equal to zero, remove the card from the queue
      newQueuedCardsDisplay = queuedCardsDisplay.filter((_, i) => i !== matchedCardIndex);
    } else {
      // Create a new collectedCards array with the updated quantity for the specific parallel
      const updatedCollectedCards = existingCard.card.collectedCards.map((parallel, index) =>
        index === parallelIndex
          ? { ...parallel, cardQty: newQuantity }  // Update the cardQty for the specific parallel
          : parallel
      );

      // Create the updated card with the modified collectedCards array
      const updatedCard = {
        ...existingCard,
        card: {
          ...existingCard.card,
          collectedCards: updatedCollectedCards
        },
        quantity: newQuantity
      };

      newQueuedCardsDisplay = [
        ...queuedCardsDisplay.slice(0, matchedCardIndex),
        updatedCard,
        ...queuedCardsDisplay.slice(matchedCardIndex + 1)
      ];
    }
  } else {
    // If no match is found, add the new card to the queue
    const newCardInfo = {
      quantity: qty,
      sport: sport,
      year: year,
      manufacturer: manufacturer,
      series: series,
      cardType: cardType,
      cardTypeCategory: cardTypeCategory,
      card: card
    };
    newQueuedCardsDisplay = [...queuedCardsDisplay, newCardInfo];
  }

  return {
    type: CARD_ACTION_TYPES.SET_QUEUED_CARDS_DISPLAY,
    payload: newQueuedCardsDisplay
  };
};

export const removeCardFromQueueDisplay = (queuedCardsDisplay, displayedIndex) => {
  const originalIndex = queuedCardsDisplay.length - 1 - displayedIndex; // Map index from reversed display order
  const newQueuedCardsDisplay = queuedCardsDisplay.filter((_, i) => i !== originalIndex);
  return { type: CARD_ACTION_TYPES.SET_QUEUED_CARDS_DISPLAY, payload: newQueuedCardsDisplay };
};

export const clearCardsFromQueue = () => {
  return { type: CARD_ACTION_TYPES.CLEAR_QUEUE }
}