import styled from "styled-components";

export const CardImgUploaderContainer = styled.div`
    display: flex;
    gap: 96px;
`
export const CardImgContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
export const CardImgDisplay = styled.div`
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border: ${({ hasImg }) =>
        hasImg ? "none" : "1px solid rgba(255, 255, 255, 0.1)"
    };
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    height: 352px;
    justify-content: center;
    position: relative;
    width: 256px;
    transition: transform 0.3s ease;
    transform: ${({ orientation }) => 
        orientation === "horizontal" ? "rotate(90deg)" : "none"}; /* Rotate for horizontal cards */
`;
export const ImgContainer = styled.div`
    align-items: center;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
    }
`;
export const OrientationToggle = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 8px;

    label {
        font-size: 14px;
        cursor: pointer;

        input {
            margin-right: 4px;
        }
    }
`;