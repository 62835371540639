// styles
import {
    TitleContainer
} from './Title.styles';


// components
import PrimaryBtn from '../../../btns/primary-btn/PrimaryBtn';

export default function Title({ text, btnPath = null }) {
    return (
        <TitleContainer>
            <h1>{text}</h1>
            {btnPath !== null &&
                <div style={{ width: "160px" }}>
                    <PrimaryBtn title={btnPath.title} path={btnPath.path} />
                </div>
            }
        </TitleContainer>
    )
}
