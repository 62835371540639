export const fadeInUp = {
    hidden: {
        opacity: 0,
        y: 4, // Move down 4px initially
    },
    visible: (customDelay = 0) => ({
        opacity: 1,
        y: 0, // Move to original position (up 4px)
        transition: {
            duration: 0.3, // Adjust the duration as needed
            ease: "easeOut",
            delay: customDelay // Use custom delay passed via the custom prop
        }
    }),
    transparent: (transparency = 0.5) => ({
        opacity: transparency,
        y: 0, // Move to original position (up 4px)
        transition: {
            duration: 0.3, // Adjust the duration as needed
            ease: "easeOut",
        }
    })
};