import styled from "styled-components";

export const SubtitleContainer = styled.div`
    align-items: start;
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 100px;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    padding: 8px 16px;

    h2 {
        color: rgba(255,255,255,1.0);
        font-family: 'Roboto Condensed';
        font-size: 14px;
        font-weight: 300;
    }
`
