import styled from "styled-components";

export const TertiaryBtnContainer = styled.button`
    align-items: center;
    background-color: ${({ $isDisabled }) => ($isDisabled ? "rgba(255,255,255,0.1)" : "rgba(255,255,255,0.1)")};
    border: 1px solid rgba(255,255,255,0.25);
    border-radius: 16px;
    box-sizing: border-box;
    color: ${({ $isDisabled }) => ($isDisabled ? "rgba(255,255,255,0.5)" : "#FFFFFF")};
    cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
    display: flex;
    font-size: 12px;
    font-weight: 700;
    gap: 16px;
    height: 32px;
    justify-content: center;
    min-width: 80px;
    padding: 0px 16px;
    transition: 0.4s;
    width: fit-content;

    img {
        height: 12px;
    }

    &:hover {
        background-color: ${({ $isDisabled }) => ($isDisabled ? "rgba(255,255,255,0.1)" : "rgba(255,255,255,0.25)")};
        transition: 0.4s;
    }
`;