import { createSelector } from "reselect";

const selectShowcaseReducer = state => state.showcase;

export const selectPreSelectedCardType = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.preSelectedCardType
);
export const selectPreSelectedCardId = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.preSelectedCardId
);
export const selectSelectedCardId = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.selectedCardId
);
export const selectNewSelectionCardData = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.newSelectionCardData
)
export const selectDreamTeamCardData_MLB = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.dreamTeamCardData_MLB
)
export const selectIsViewingNewSelection = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.isViewingNewSelection
)
export const selectIsEditingCard = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.isEditingCard
)
export const selectIsAddingCardImgs = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.isAddingCardImgs
)
export const selectCardImgsWereAdded = createSelector(
    [selectShowcaseReducer],
    (showcase) => showcase.cardImgsWereAdded
)