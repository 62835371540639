import styled from "styled-components";

export const ShowcaseSelectionViewContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: 100%;
    justify-content: center;
    overflow: visible;
    position: relative;
    width: 100%;
`