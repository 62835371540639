// styles
import { SubtitleContainer } from './Subtitle.styles';

// components
import InfoIcon from '../info-icon/InfoIcon';

export default function Subtitle({ text, infoKeyIsDisplayed }) {
    return (
        <SubtitleContainer>
            <h2>{text}</h2>
            {infoKeyIsDisplayed &&
                <InfoIcon />
            }
        </SubtitleContainer >
    )
}
