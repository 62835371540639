import styled from "styled-components";

export const BullpenDisplayContainer = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    height: 100%;
    padding: 72px;
    position: relative;
    width: 40%;
`
export const Bullpen = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 48px;
`
export const BullpenCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 448px;
`
export const CardGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
        font-family: 'Roboto Condensed';
        font-size: 24px;
        opacity: 0.8;
    }
`