import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/user/user.selector";
import { fetchAndSetDreamTeamData } from "../../utils/firebase/firebase.utils";

// styles
import {
  ShowcaseContainer
} from "./Showcase.styles";
import { useEffect } from "react";

export default function Showcase() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    fetchAndSetDreamTeamData(user.uid, dispatch);
  }, [dispatch, user.uid])

  return (
    <ShowcaseContainer className='widget-bg'>
      <Outlet />
    </ShowcaseContainer>
  );
}