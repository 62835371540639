import styled from "styled-components";
import { motion } from "framer-motion";

export const CardDisplayContainer = styled(motion.div)`
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
`
export const ImgContainer = styled.div`
    align-items: center;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    background: ${props => props.$hasAssignedCard ? "rgba(255, 255, 255, 0.4)" : "rgba(255, 255, 255, 0.1)"};
    border: ${props => props.$isActive ? "2px solid #FFFFFF" : "none"};
    box-sizing: border-box;
    display: flex;
    height: 88px;
    justify-content: center;
    width: 64px;

    img {
        opacity: 0.4;
        width: 40%;
    }
`
export const PositionContainer = styled.div`
    font-weight: 500;
`