export const SHOWCASE_ACTION_TYPES = {
    SET_PRESELECTED_CARD_ID: 'showcase/SET_PRESELECTED_CARD_ID',
    SET_PRESELECTED_CARD_TYPE: 'showcase/SET_PRESELECTED_CARD_TYPE',
    SET_SELECTED_CARD_ID: 'showcase/SET_SELECTED_CARD_ID',
    SET_NEW_SELECTION_CARD_DATA: 'showcase/SET_NEW_SELECTION_CARD_DATA',
    SET_IS_VIEWING_NEW_SELECTION: 'showcase/SET_IS_VIEWING_NEW_SELECTION',
    SET_IS_EDITING_CARD: 'showcase/SET_IS_EDITING_CARD',
    SET_IS_ADDING_CARD_IMGS: 'showcase/SET_IS_ADDING_CARD_IMGS',
    SET_CARD_IMGS_WERE_ADDED: 'showcase/SET_CARD_IMGS_WERE_ADDED',
    SET_DREAM_TEAM_MLB: 'showcase/SET_DREAM_TEAM_MLB',
    UPDATE_DREAM_TEAM_POSITION: 'showcase/UPDATE_DREAM_TEAM_POSITION'
};