import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateFirestorePositionData } from '../../../../../../utils/firebase/firebase.utils';
import { updateReduxPositionData } from '../../../../../../store/showcase/showcase.action';
import { selectUser } from '../../../../../../store/user/user.selector';
import {
    selectCardImgsWereAdded,
    selectIsAddingCardImgs,
    selectIsEditingCard,
    selectIsViewingNewSelection,
    selectNewSelectionCardData,
    selectPreSelectedCardId
} from '../../../../../../store/showcase/showcase.selector';
import {
    setIsAddingCardImgs,
    setIsEditingCard,
    setIsViewingNewSelection,
    setPreselectedCardId,
    setPreselectedCardType,
    setSelectedCardId,
} from '../../../../../../store/showcase/showcase.action';

// styles
import {
    CardOptionsContainer,
    LeftOptions
} from './CardOptions.styles';

// components
import TertiaryBtn from '../../../../../../components/btns/tertiary-btn/TertiaryBtn';

export default function CardOptions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const cardImgsWereAdded = useSelector(selectCardImgsWereAdded);
    const isAddingCardImgs = useSelector(selectIsAddingCardImgs);
    const isEditingCard = useSelector(selectIsEditingCard);
    const isViewingNewCardSelection = useSelector(selectIsViewingNewSelection);
    const newSelectionCardData = useSelector(selectNewSelectionCardData);
    const preselectedCardId = useSelector(selectPreSelectedCardId);

    const imageWasUploaded = false; // Placeholder - this should come from a state or selector
    const imageExists = false; // Placeholder - this should come from a state or selector

    const handleBack = () => {
        if (newSelectionCardData.name.length === 0) {
            dispatch(setSelectedCardId(null));
            dispatch(setPreselectedCardType(null));
            dispatch(setPreselectedCardId(null));
        } else {
            navigate(-1);
        }
        dispatch(setIsViewingNewSelection(false));
    };

    const handleSaveNewCard = () => {
        // upload to firestore
        updateFirestorePositionData(user.uid, "dreamTeamCardData_MLB", preselectedCardId, newSelectionCardData);
        dispatch(updateReduxPositionData(preselectedCardId, newSelectionCardData));
        navigate('/');
    }

    const toggleEditMode = () => dispatch(setIsEditingCard(!isEditingCard));
    const handleAddingCardImgs = (status) => dispatch(setIsAddingCardImgs(status));

    const renderImageOptions = () => {
        if (isViewingNewCardSelection) {
            if (imageWasUploaded) {
                return (
                    <>
                        <TertiaryBtn title={"REPLACE IMAGE"} />
                        <TertiaryBtn title={"ROTATE IMAGE"} />
                        <TertiaryBtn title={"REMOVE IMAGE"} />
                    </>
                );
            } else if (!isAddingCardImgs) {
                return (
                    <div onClick={() => handleAddingCardImgs(true)}>
                        <TertiaryBtn title={"UPLOAD IMAGE"} />
                    </div>
                );
            }
        }

        if (isEditingCard && !imageExists) {
            return (
                <div onClick={() => handleAddingCardImgs(true)}>
                    <TertiaryBtn title={"UPLOAD IMAGE"} />
                </div>
            );
        }

        return null;
    };

    return (
        <CardOptionsContainer>
            <LeftOptions>
                <div onClick={isAddingCardImgs ? () => handleAddingCardImgs(false) : handleBack}>
                    <TertiaryBtn title={isAddingCardImgs ? "CANCEL" : "BACK"} backIcon={!isAddingCardImgs} />
                </div>

                {!isViewingNewCardSelection && (
                    <>
                        <div onClick={toggleEditMode}>
                            <TertiaryBtn title={isEditingCard ? "CANCEL" : "EDIT"} />
                        </div>
                        {isEditingCard && (
                            <>
                                <TertiaryBtn title={"CHANGE CARD"} />
                                {renderImageOptions()}
                            </>
                        )}
                    </>
                )}
                {isViewingNewCardSelection && isAddingCardImgs && (
                    <div onClick={handleAddingCardImgs}>
                        <TertiaryBtn title={"ADD"} disabled={!cardImgsWereAdded} />
                    </div>
                )}
            </LeftOptions>
            {isViewingNewCardSelection && !isAddingCardImgs && (
                <div onClick={handleSaveNewCard}>
                    <TertiaryBtn title={"SAVE"} disabled={newSelectionCardData === null}/>
                </div>
            )}
        </CardOptionsContainer>
    );
}