import styled from "styled-components";

export const HeaderWidgetContainer = styled.div`
    align-items: start;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    width: 100%;
`