import styled from "styled-components";

export const DreamTeamContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
`
export const TitleContainer = styled.div`
    left: 0;
    position: absolute;
    top: 0px;
`
export const ContentContainer = styled.div`
    align-items: center;
    display: flex;
    height: 600px;
    justify-content: center;
    position: relative;
    width: 100%;
`