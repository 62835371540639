// CHECKLIST: COMPLETE
// PARALLELS: COMPLETE

export const MLB_2024_TOPPS_CHROME_UPDATE_SERIES = {
    "MLB": {
        "2024": {
            "Topps": {
                "Topps Chrome® Baseball Update Series": {
                    "Base Card Set": {
                        "Base": {
                            "USC1": {
                                "cardNumber": "USC1",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "USC2": {
                                "cardNumber": "USC2",
                                "name": ["Jorge Soler"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "USC3": {
                                "cardNumber": "USC3",
                                "name": ["Christopher Morel"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "USC4": {
                                "cardNumber": "USC4",
                                "name": ["Carlos Carrasco"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "USC5": {
                                "cardNumber": "USC5",
                                "name": ["Mitch Garver"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "USC6": {
                                "cardNumber": "USC6",
                                "name": ["Aroldis Chapman"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "USC7": {
                                "cardNumber": "USC7",
                                "name": ["Joey Gallo"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "USC8": {
                                "cardNumber": "USC8",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC9": {
                                "cardNumber": "USC9",
                                "name": ["Naoyuki Uwasawa"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC10": {
                                "cardNumber": "USC10",
                                "name": ["Zach Eflin"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "USC11": {
                                "cardNumber": "USC11",
                                "name": ["Michael Lorenzen"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "USC12": {
                                "cardNumber": "USC12",
                                "name": ["Jonathan Cannon"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC13": {
                                "cardNumber": "USC13",
                                "name": ["Mitchell Parker"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC14": {
                                "cardNumber": "USC14",
                                "name": ["Nick Nastrini"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC15": {
                                "cardNumber": "USC15",
                                "name": ["Miguel Andujar"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "USC16": {
                                "cardNumber": "USC16",
                                "name": ["Trevor Rogers"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "USC17": {
                                "cardNumber": "USC17",
                                "name": ["Spencer Arrighetti"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC18": {
                                "cardNumber": "USC18",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC19": {
                                "cardNumber": "USC19",
                                "name": ["Daniel Hudson"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "USC20": {
                                "cardNumber": "USC20",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC21": {
                                "cardNumber": "USC21",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC22": {
                                "cardNumber": "USC22",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC23": {
                                "cardNumber": "USC23",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC24": {
                                "cardNumber": "USC24",
                                "name": ["José Caballero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "USC25": {
                                "cardNumber": "USC25",
                                "name": ["Tobias Myers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC26": {
                                "cardNumber": "USC26",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC27": {
                                "cardNumber": "USC27",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC28": {
                                "cardNumber": "USC28",
                                "name": ["Samad Taylor"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC29": {
                                "cardNumber": "USC29",
                                "name": ["Pedro Pagés"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC30": {
                                "cardNumber": "USC30",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC31": {
                                "cardNumber": "USC31",
                                "name": ["Bryan Ramos"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC32": {
                                "cardNumber": "USC32",
                                "name": ["Austin Hays"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "USC33": {
                                "cardNumber": "USC33",
                                "name": ["Lenyn Sosa"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "USC34": {
                                "cardNumber": "USC34",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC35": {
                                "cardNumber": "USC35",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC36": {
                                "cardNumber": "USC36",
                                "name": ["Jake Bauers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "USC37": {
                                "cardNumber": "USC37",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC38": {
                                "cardNumber": "USC38",
                                "name": ["Alex Verdugo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "USC39": {
                                "cardNumber": "USC39",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "USC40": {
                                "cardNumber": "USC40",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC41": {
                                "cardNumber": "USC41",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC42": {
                                "cardNumber": "USC42",
                                "name": ["Max Schuemann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC43": {
                                "cardNumber": "USC43",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC44": {
                                "cardNumber": "USC44",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC45": {
                                "cardNumber": "USC45",
                                "name": ["Simeon Woods Richardson"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC46": {
                                "cardNumber": "USC46",
                                "name": ["Justin Slaten"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC47": {
                                "cardNumber": "USC47",
                                "name": ["Erick Fedde"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "USC48": {
                                "cardNumber": "USC48",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC49": {
                                "cardNumber": "USC49",
                                "name": ["Nelson Velázquez"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "USC50": {
                                "cardNumber": "USC50",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC51": {
                                "cardNumber": "USC51",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC52": {
                                "cardNumber": "USC52",
                                "name": ["Isaac Paredes"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "USC53": {
                                "cardNumber": "USC53",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC54": {
                                "cardNumber": "USC54",
                                "name": ["Ramon Urías"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "USC55": {
                                "cardNumber": "USC55",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC56": {
                                "cardNumber": "USC56",
                                "name": ["Trevor Williams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "USC57": {
                                "cardNumber": "USC57",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC58": {
                                "cardNumber": "USC58",
                                "name": ["Adrian Houser"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "USC59": {
                                "cardNumber": "USC59",
                                "name": ["Blake Snell"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "USC60": {
                                "cardNumber": "USC60",
                                "name": ["Ricky Vanasco"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC61": {
                                "cardNumber": "USC61",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC62": {
                                "cardNumber": "USC62",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC63": {
                                "cardNumber": "USC63",
                                "name": ["Elvis Peguero"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "USC64": {
                                "cardNumber": "USC64",
                                "name": ["Santiago Espinal"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "USC65": {
                                "cardNumber": "USC65",
                                "name": ["Kevin Pillar"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "USC66": {
                                "cardNumber": "USC66",
                                "name": ["Stephen Kolek"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC67": {
                                "cardNumber": "USC67",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC68": {
                                "cardNumber": "USC68",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC69": {
                                "cardNumber": "USC69",
                                "name": ["Blake Walston"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC70": {
                                "cardNumber": "USC70",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC71": {
                                "cardNumber": "USC71",
                                "name": ["J.P. France"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "USC72": {
                                "cardNumber": "USC72",
                                "name": ["Martín Perez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "USC73": {
                                "cardNumber": "USC73",
                                "name": ["Victor Caratini"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "USC74": {
                                "cardNumber": "USC74",
                                "name": ["Eugenio Suárez"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "USC75": {
                                "cardNumber": "USC75",
                                "name": ["Gavin Stone"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "USC76": {
                                "cardNumber": "USC76",
                                "name": ["Leo Rivas"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC77": {
                                "cardNumber": "USC77",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC78": {
                                "cardNumber": "USC78",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "USC79": {
                                "cardNumber": "USC79",
                                "name": ["Dakota Hudson"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "USC80": {
                                "cardNumber": "USC80",
                                "name": ["Cole Winn"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC81": {
                                "cardNumber": "USC81",
                                "name": ["Jeimer Candelario"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "USC82": {
                                "cardNumber": "USC82",
                                "name": ["Frankie Montas"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "USC83": {
                                "cardNumber": "USC83",
                                "name": ["Davis Wendzel"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC84": {
                                "cardNumber": "USC84",
                                "name": ["Justin Turner"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "USC85": {
                                "cardNumber": "USC85",
                                "name": ["Kris Bubic"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "USC86": {
                                "cardNumber": "USC86",
                                "name": ["Joey Estes"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC87": {
                                "cardNumber": "USC87",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC88": {
                                "cardNumber": "USC88",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC89": {
                                "cardNumber": "USC89",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC90": {
                                "cardNumber": "USC90",
                                "name": ["Louie Varland"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": []
                            },
                            "USC91": {
                                "cardNumber": "USC91",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC92": {
                                "cardNumber": "USC92",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC93": {
                                "cardNumber": "USC93",
                                "name": ["Marcell Ozuna"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "USC94": {
                                "cardNumber": "USC94",
                                "name": ["Jordan Leasure"],
                                "team": ["Chicago White Sox"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC95": {
                                "cardNumber": "USC95",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC96": {
                                "cardNumber": "USC96",
                                "name": ["Cam Eden"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC97": {
                                "cardNumber": "USC97",
                                "name": ["Graham Pauley"],
                                "team": ["Miami Marlins"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC98": {
                                "cardNumber": "USC98",
                                "name": ["Michael Kopech"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "USC99": {
                                "cardNumber": "USC99",
                                "name": ["Jose Trevino"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "USC100": {
                                "cardNumber": "USC100",
                                "name": ["Luis Arraez"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "USC101": {
                                "cardNumber": "USC101",
                                "name": ["Cristopher Sánchez"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "USC102": {
                                "cardNumber": "USC102",
                                "name": ["Kai-Wei Teng"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC103": {
                                "cardNumber": "USC103",
                                "name": ["Yusei Kikuchi"],
                                "team": ["Houston Astros"],
                                "cardAttributes": []
                            },
                            "USC104": {
                                "cardNumber": "USC104",
                                "name": ["Landen Roupp"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC105": {
                                "cardNumber": "USC105",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC106": {
                                "cardNumber": "USC106",
                                "name": ["Randy Rodriguez"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC107": {
                                "cardNumber": "USC107",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC108": {
                                "cardNumber": "USC108",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC109": {
                                "cardNumber": "USC109",
                                "name": ["J.D. Martinez"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "USC110": {
                                "cardNumber": "USC110",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC111": {
                                "cardNumber": "USC111",
                                "name": ["Tommy Pham"],
                                "team": ["St. Louis Cardinals"],
                                "cardAttributes": []
                            },
                            "USC112": {
                                "cardNumber": "USC112",
                                "name": ["Tyler Rogers"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": []
                            },
                            "USC113": {
                                "cardNumber": "USC113",
                                "name": ["Eduardo Rodriguez"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": []
                            },
                            "USC114": {
                                "cardNumber": "USC114",
                                "name": ["Jack Flaherty"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "USC115": {
                                "cardNumber": "USC115",
                                "name": ["Ryan Fernandez"],
                                "team": ["St. Louis Cardinals"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC116": {
                                "cardNumber": "USC116",
                                "name": ["Paul Blackburn"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "USC117": {
                                "cardNumber": "USC117",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC118": {
                                "cardNumber": "USC118",
                                "name": ["Clayton Beeter"],
                                "team": ["New York Yankees"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC119": {
                                "cardNumber": "USC119",
                                "name": ["Austin Shenton"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC120": {
                                "cardNumber": "USC120",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC121": {
                                "cardNumber": "USC121",
                                "name": ["Mason Black"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC122": {
                                "cardNumber": "USC122",
                                "name": ["Amed Rosario"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": []
                            },
                            "USC123": {
                                "cardNumber": "USC123",
                                "name": ["Austin Hedges"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": []
                            },
                            "USC124": {
                                "cardNumber": "USC124",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC125": {
                                "cardNumber": "USC125",
                                "name": ["Ronel Blanco"],
                                "team": ["Houston Astros"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC126": {
                                "cardNumber": "USC126",
                                "name": ["Bryan De La Cruz"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": []
                            },
                            "USC127": {
                                "cardNumber": "USC127",
                                "name": ["Tyler O'Neill"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "USC128": {
                                "cardNumber": "USC128",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC129": {
                                "cardNumber": "USC129",
                                "name": ["Alek Jacob"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC130": {
                                "cardNumber": "USC130",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC131": {
                                "cardNumber": "USC131",
                                "name": ["Fernando Cruz"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC132": {
                                "cardNumber": "USC132",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC133": {
                                "cardNumber": "USC133",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC134": {
                                "cardNumber": "USC134",
                                "name": ["Trey Lipscomb"],
                                "team": ["Washington Nationals"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC135": {
                                "cardNumber": "USC135",
                                "name": ["Oliver Dunn"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC136": {
                                "cardNumber": "USC136",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC137": {
                                "cardNumber": "USC137",
                                "name": ["Hunter Stratton"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC138": {
                                "cardNumber": "USC138",
                                "name": ["Adam Ottavino"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "USC139": {
                                "cardNumber": "USC139",
                                "name": ["A.J. Puk"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": []
                            },
                            "USC140": {
                                "cardNumber": "USC140",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC141": {
                                "cardNumber": "USC141",
                                "name": ["Jacob Hurtubise"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC142": {
                                "cardNumber": "USC142",
                                "name": ["Kyle McCann"],
                                "team": ["Oakland Athletics"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC143": {
                                "cardNumber": "USC143",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC144": {
                                "cardNumber": "USC144",
                                "name": ["LaMonte Wade Jr."],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": []
                            },
                            "USC145": {
                                "cardNumber": "USC145",
                                "name": ["Adam Cimber"],
                                "team": ["Angels"],
                                "cardAttributes": []
                            },
                            "USC146": {
                                "cardNumber": "USC146",
                                "name": ["Steven Matz"],
                                "team": ["St. Louis Cardinals"],
                                "cardAttributes": []
                            },
                            "USC147": {
                                "cardNumber": "USC147",
                                "name": ["Jonatan Clase"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC148": {
                                "cardNumber": "USC148",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC149": {
                                "cardNumber": "USC149",
                                "name": ["Robert Garcia"],
                                "team": ["Washington Nationals"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC150": {
                                "cardNumber": "USC150",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC151": {
                                "cardNumber": "USC151",
                                "name": ["Travis Jankowski"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": []
                            },
                            "USC152": {
                                "cardNumber": "USC152",
                                "name": ["Lucas Giolito"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "USC153": {
                                "cardNumber": "USC153",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC154": {
                                "cardNumber": "USC154",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC155": {
                                "cardNumber": "USC155",
                                "name": ["Matt Sauer"],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC156": {
                                "cardNumber": "USC156",
                                "name": ["Joey Ortiz"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": []
                            },
                            "USC157": {
                                "cardNumber": "USC157",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC158": {
                                "cardNumber": "USC158",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC159": {
                                "cardNumber": "USC159",
                                "name": ["Addison Barger"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC160": {
                                "cardNumber": "USC160",
                                "name": ["Luis Gil"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "USC161": {
                                "cardNumber": "USC161",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC162": {
                                "cardNumber": "USC162",
                                "name": ["Dylan Lee"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "USC163": {
                                "cardNumber": "USC163",
                                "name": ["Jorge Barrosa"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC164": {
                                "cardNumber": "USC164",
                                "name": ["Roddery Muñoz"],
                                "team": ["Miami Marlins"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC165": {
                                "cardNumber": "USC165",
                                "name": ["Adam Duvall"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "USC166": {
                                "cardNumber": "USC166",
                                "name": ["Mitch Spence"],
                                "team": ["Oakland Athletics"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC167": {
                                "cardNumber": "USC167",
                                "name": ["Wenceel Pérez"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC168": {
                                "cardNumber": "USC168",
                                "name": ["Manuel Margot"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": []
                            },
                            "USC169": {
                                "cardNumber": "USC169",
                                "name": ["Bryan Hudson"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC170": {
                                "cardNumber": "USC170",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "USC171": {
                                "cardNumber": "USC171",
                                "name": ["Brandon Crawford"],
                                "team": ["St. Louis Cardinals"],
                                "cardAttributes": []
                            },
                            "USC172": {
                                "cardNumber": "USC172",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC173": {
                                "cardNumber": "USC173",
                                "name": ["Nasim Nuñez"],
                                "team": ["Washington Nationals"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC174": {
                                "cardNumber": "USC174",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC175": {
                                "cardNumber": "USC175",
                                "name": ["Alex Speas"],
                                "team": ["Houston Astros"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC176": {
                                "cardNumber": "USC176",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC177": {
                                "cardNumber": "USC177",
                                "name": ["Jorge Polanco"],
                                "team": ["Seattle Mariners"],
                                "cardAttributes": []
                            },
                            "USC178": {
                                "cardNumber": "USC178",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC179": {
                                "cardNumber": "USC179",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC180": {
                                "cardNumber": "USC180",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "USC181": {
                                "cardNumber": "USC181",
                                "name": ["Emmanuel Rivera"],
                                "team": ["Miami Marlins"],
                                "cardAttributes": []
                            },
                            "USC182": {
                                "cardNumber": "USC182",
                                "name": ["Lane Thomas"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": []
                            },
                            "USC183": {
                                "cardNumber": "USC183",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC184": {
                                "cardNumber": "USC184",
                                "name": ["Dylan Cease"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "USC185": {
                                "cardNumber": "USC185",
                                "name": ["Jung HOO Lee"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC186": {
                                "cardNumber": "USC186",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC187": {
                                "cardNumber": "USC187",
                                "name": ["Yuki Matsui"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC188": {
                                "cardNumber": "USC188",
                                "name": ["Cade Smith"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC189": {
                                "cardNumber": "USC189",
                                "name": ["Eddie Rosario"],
                                "team": ["Washington Nationals"],
                                "cardAttributes": []
                            },
                            "USC190": {
                                "cardNumber": "USC190",
                                "name": ["Eloy Jiménez"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "USC191": {
                                "cardNumber": "USC191",
                                "name": ["Kevin Kiermaier"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "USC192": {
                                "cardNumber": "USC192",
                                "name": ["Tyler Holton"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC193": {
                                "cardNumber": "USC193",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC194": {
                                "cardNumber": "USC194",
                                "name": ["Randy Arozarena"],
                                "team": ["Seattle Mariners"],
                                "cardAttributes": []
                            },
                            "USC195": {
                                "cardNumber": "USC195",
                                "name": ["Joey Loperfido"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC196": {
                                "cardNumber": "USC196",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC197": {
                                "cardNumber": "USC197",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC198": {
                                "cardNumber": "USC198",
                                "name": ["Gio Urshela"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "USC199": {
                                "cardNumber": "USC199",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie Debut"]
                            },
                            "USC200": {
                                "cardNumber": "USC200",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Base Cards Image Variation": {
                            "USC8": {
                                "cardNumber": "USC8",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC18": {
                                "cardNumber": "USC18",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC20": {
                                "cardNumber": "USC20",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC35": {
                                "cardNumber": "USC35",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC48": {
                                "cardNumber": "USC48",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC51": {
                                "cardNumber": "USC51",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC67": {
                                "cardNumber": "USC67",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC70": {
                                "cardNumber": "USC70",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC88": {
                                "cardNumber": "USC88",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC91": {
                                "cardNumber": "USC91",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC105": {
                                "cardNumber": "USC105",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC108": {
                                "cardNumber": "USC108",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC120": {
                                "cardNumber": "USC120",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC143": {
                                "cardNumber": "USC143",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC147": {
                                "cardNumber": "USC147",
                                "name": ["Jonatan Clase"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC148": {
                                "cardNumber": "USC148",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC150": {
                                "cardNumber": "USC150",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC153": {
                                "cardNumber": "USC153",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC154": {
                                "cardNumber": "USC154",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC158": {
                                "cardNumber": "USC158",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC174": {
                                "cardNumber": "USC174",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC185": {
                                "cardNumber": "USC185",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC195": {
                                "cardNumber": "USC195",
                                "name": ["Joey Loperfido"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC199": {
                                "cardNumber": "USC199",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "USC200": {
                                "cardNumber": "USC200",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            }
                        }
                    },
                    "Insert": {
                        "2024 All Star Game Chrome": {
                            "ASGC-1": {
                                "cardNumber": "ASGC-1",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "ASGC-2": {
                                "cardNumber": "ASGC-2",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": []
                            },
                            "ASGC-3": {
                                "cardNumber": "ASGC-3",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros"],
                                "cardAttributes": []
                            },
                            "ASGC-4": {
                                "cardNumber": "ASGC-4",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": []
                            },
                            "ASGC-5": {
                                "cardNumber": "ASGC-5",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "ASGC-6": {
                                "cardNumber": "ASGC-6",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "ASGC-7": {
                                "cardNumber": "ASGC-7",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "ASGC-8": {
                                "cardNumber": "ASGC-8",
                                "name": ["Steven Kwan"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": []
                            },
                            "ASGC-9": {
                                "cardNumber": "ASGC-9",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros"],
                                "cardAttributes": []
                            },
                            "ASGC-10": {
                                "cardNumber": "ASGC-10",
                                "name": ["William Contreras"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": []
                            },
                            "ASGC-11": {
                                "cardNumber": "ASGC-11",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "ASGC-12": {
                                "cardNumber": "ASGC-12",
                                "name": ["Ketel Marte"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": []
                            },
                            "ASGC-13": {
                                "cardNumber": "ASGC-13",
                                "name": ["Alec Bohm"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "ASGC-14": {
                                "cardNumber": "ASGC-14",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "ASGC-15": {
                                "cardNumber": "ASGC-15",
                                "name": ["Jurickson Profar"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "ASGC-16": {
                                "cardNumber": "ASGC-16",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "ASGC-17": {
                                "cardNumber": "ASGC-17",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": []
                            },
                            "ASGC-18": {
                                "cardNumber": "ASGC-18",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "ASGC-19": {
                                "cardNumber": "ASGC-19",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "ASGC-20": {
                                "cardNumber": "ASGC-20",
                                "name": ["Emmanuel Clase"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": []
                            },
                            "ASGC-21": {
                                "cardNumber": "ASGC-21",
                                "name": ["Garrett Crochet"],
                                "team": ["Chicago White Sox"],
                                "cardAttributes": []
                            },
                            "ASGC-22": {
                                "cardNumber": "ASGC-22",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "ASGC-23": {
                                "cardNumber": "ASGC-23",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics"],
                                "cardAttributes": []
                            },
                            "ASGC-24": {
                                "cardNumber": "ASGC-24",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "ASGC-25": {
                                "cardNumber": "ASGC-25",
                                "name": ["Tarik Skubal"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": []
                            },
                            "ASGC-26": {
                                "cardNumber": "ASGC-26",
                                "name": ["Tyler Glasnow"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "ASGC-27": {
                                "cardNumber": "ASGC-27",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGC-28": {
                                "cardNumber": "ASGC-28",
                                "name": ["Chris Sale"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "ASGC-29": {
                                "cardNumber": "ASGC-29",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGC-30": {
                                "cardNumber": "ASGC-30",
                                "name": ["Ranger Suarez"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "ASGC-31": {
                                "cardNumber": "ASGC-31",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": []
                            },
                            "ASGC-32": {
                                "cardNumber": "ASGC-32",
                                "name": ["Zack Wheeler"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "ASGC-33": {
                                "cardNumber": "ASGC-33",
                                "name": ["Salvador Perez"],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "ASGC-34": {
                                "cardNumber": "ASGC-34",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "ASGC-35": {
                                "cardNumber": "ASGC-35",
                                "name": ["Matt Strahm"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "ASGC-36": {
                                "cardNumber": "ASGC-36",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "ASGC-37": {
                                "cardNumber": "ASGC-37",
                                "name": ["Josh Naylor"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": []
                            },
                            "ASGC-38": {
                                "cardNumber": "ASGC-38",
                                "name": ["Marcus Semien"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": []
                            },
                            "ASGC-39": {
                                "cardNumber": "ASGC-39",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "ASGC-40": {
                                "cardNumber": "ASGC-40",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals"],
                                "cardAttributes": []
                            },
                            "ASGC-41": {
                                "cardNumber": "ASGC-41",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "ASGC-42": {
                                "cardNumber": "ASGC-42",
                                "name": ["Luis Arraez"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "ASGC-43": {
                                "cardNumber": "ASGC-43",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "ASGC-44": {
                                "cardNumber": "ASGC-44",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGC-45": {
                                "cardNumber": "ASGC-45",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "ASGC-46": {
                                "cardNumber": "ASGC-46",
                                "name": ["Jarren Duran"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "ASGC-47": {
                                "cardNumber": "ASGC-47",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": []
                            },
                            "ASGC-48": {
                                "cardNumber": "ASGC-48",
                                "name": ["Kyle Tucker"],
                                "team": ["Houston Astros"],
                                "cardAttributes": []
                            },
                            "ASGC-49": {
                                "cardNumber": "ASGC-49",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGC-50": {
                                "cardNumber": "ASGC-50",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": []
                            }
                        },
                        "Stratospheric Stars": {
                            "SSC-1": {
                                "cardNumber": "SSC-1",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners"],
                                "cardAttributes": []
                            },
                            "SSC-2": {
                                "cardNumber": "SSC-2",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres"],
                                "cardAttributes": []
                            },
                            "SSC-3": {
                                "cardNumber": "SSC-3",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "SSC-4": {
                                "cardNumber": "SSC-4",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "SSC-5": {
                                "cardNumber": "SSC-5",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "SSC-6": {
                                "cardNumber": "SSC-6",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "SSC-7": {
                                "cardNumber": "SSC-7",
                                "name": ["Mike Trout"],
                                "team": ["Angels"],
                                "cardAttributes": []
                            },
                            "SSC-8": {
                                "cardNumber": "SSC-8",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "SSC-9": {
                                "cardNumber": "SSC-9",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            },
                            "SSC-10": {
                                "cardNumber": "SSC-10",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-11": {
                                "cardNumber": "SSC-11",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-12": {
                                "cardNumber": "SSC-12",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-13": {
                                "cardNumber": "SSC-13",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-14": {
                                "cardNumber": "SSC-14",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-15": {
                                "cardNumber": "SSC-15",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-16": {
                                "cardNumber": "SSC-16",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-17": {
                                "cardNumber": "SSC-17",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-18": {
                                "cardNumber": "SSC-18",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-19": {
                                "cardNumber": "SSC-19",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-20": {
                                "cardNumber": "SSC-20",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros"],
                                "cardAttributes": []
                            },
                            "SSC-21": {
                                "cardNumber": "SSC-21",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "SSC-22": {
                                "cardNumber": "SSC-22",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "SSC-23": {
                                "cardNumber": "SSC-23",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-24": {
                                "cardNumber": "SSC-24",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "SSC-25": {
                                "cardNumber": "SSC-25",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "SSC-26": {
                                "cardNumber": "SSC-26",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "SSC-27": {
                                "cardNumber": "SSC-27",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": []
                            },
                            "SSC-28": {
                                "cardNumber": "SSC-28",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "SSC-29": {
                                "cardNumber": "SSC-29",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "SSC-30": {
                                "cardNumber": "SSC-30",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": []
                            },
                            "SSC-31": {
                                "cardNumber": "SSC-31",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": []
                            },
                            "SSC-32": {
                                "cardNumber": "SSC-32",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox"],
                                "cardAttributes": []
                            },
                            "SSC-33": {
                                "cardNumber": "SSC-33",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": []
                            },
                            "SSC-34": {
                                "cardNumber": "SSC-34",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SSC-35": {
                                "cardNumber": "SSC-35",
                                "name": ["Jazz Chisholm Jr."],
                                "team": ["New York Yankees"],
                                "cardAttributes": []
                            }
                        },
                        "2024 Topps Chrome Youthquake": {
                            "YQ-51": {
                                "cardNumber": "YQ-51",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-52": {
                                "cardNumber": "YQ-52",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-53": {
                                "cardNumber": "YQ-53",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-54": {
                                "cardNumber": "YQ-54",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-55": {
                                "cardNumber": "YQ-55",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-56": {
                                "cardNumber": "YQ-56",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-57": {
                                "cardNumber": "YQ-57",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-58": {
                                "cardNumber": "YQ-58",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-59": {
                                "cardNumber": "YQ-59",
                                "name": ["Oliver Dunn"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-60": {
                                "cardNumber": "YQ-60",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-61": {
                                "cardNumber": "YQ-61",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-62": {
                                "cardNumber": "YQ-62",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-63": {
                                "cardNumber": "YQ-63",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-64": {
                                "cardNumber": "YQ-64",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-65": {
                                "cardNumber": "YQ-65",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-66": {
                                "cardNumber": "YQ-66",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-67": {
                                "cardNumber": "YQ-67",
                                "name": ["Jonatan Clase"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-68": {
                                "cardNumber": "YQ-68",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-69": {
                                "cardNumber": "YQ-69",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-70": {
                                "cardNumber": "YQ-70",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-71": {
                                "cardNumber": "YQ-71",
                                "name": ["Joey Loperfido"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-72": {
                                "cardNumber": "YQ-72",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-73": {
                                "cardNumber": "YQ-73",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-74": {
                                "cardNumber": "YQ-74",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-75": {
                                "cardNumber": "YQ-75",
                                "name": ["Bryan Ramos"],
                                "team": ["Chicago White Sox"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-76": {
                                "cardNumber": "YQ-76",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-77": {
                                "cardNumber": "YQ-77",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-78": {
                                "cardNumber": "YQ-78",
                                "name": ["Graham Pauley"],
                                "team": ["Miami Marlins"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-79": {
                                "cardNumber": "YQ-79",
                                "name": ["Yuki Matsui"],
                                "team": ["San Diego Padres"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-80": {
                                "cardNumber": "YQ-80",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-81": {
                                "cardNumber": "YQ-81",
                                "name": ["Clayton Beeter"],
                                "team": ["New York Yankees"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-82": {
                                "cardNumber": "YQ-82",
                                "name": ["Trey Lipscomb"],
                                "team": ["Washington Nationals"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-83": {
                                "cardNumber": "YQ-83",
                                "name": ["Kyle McCann"],
                                "team": ["Oakland Athletics"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-84": {
                                "cardNumber": "YQ-84",
                                "name": ["Addison Barger"],
                                "team": ["Toronto Blue Jays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-85": {
                                "cardNumber": "YQ-85",
                                "name": ["Everson Pereira"],
                                "team": ["New York Yankees"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-86": {
                                "cardNumber": "YQ-86",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-87": {
                                "cardNumber": "YQ-87",
                                "name": ["Jonny Deluca"],
                                "team": ["Tampa Bay Rays"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-88": {
                                "cardNumber": "YQ-88",
                                "name": ["Johan Rojas"],
                                "team": ["Philadelphia Phillies"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-89": {
                                "cardNumber": "YQ-89",
                                "name": ["Wilyer Abreu"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-90": {
                                "cardNumber": "YQ-90",
                                "name": ["Keaton Winn"],
                                "team": ["San Francisco Giants"],
                                "cardAttributes": ["Rookie"]
                            },
                            "YQ-91": {
                                "cardNumber": "YQ-91",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles"],
                                "cardAttributes": []
                            },
                            "YQ-92": {
                                "cardNumber": "YQ-92",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves"],
                                "cardAttributes": []
                            },
                            "YQ-93": {
                                "cardNumber": "YQ-93",
                                "name": ["Mark Vientos"],
                                "team": ["New York Mets"],
                                "cardAttributes": []
                            },
                            "YQ-94": {
                                "cardNumber": "YQ-94",
                                "name": ["Vinnie Pasquantino"],
                                "team": ["Kansas City Royals"],
                                "cardAttributes": []
                            },
                            "YQ-95": {
                                "cardNumber": "YQ-95",
                                "name": ["Triston Casas"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            },
                            "YQ-96": {
                                "cardNumber": "YQ-96",
                                "name": ["Ezequiel Tovar"],
                                "team": ["Colorado Rockies"],
                                "cardAttributes": []
                            },
                            "YQ-97": {
                                "cardNumber": "YQ-97",
                                "name": ["Zach Neto"],
                                "team": ["Angels"],
                                "cardAttributes": []
                            },
                            "YQ-98": {
                                "cardNumber": "YQ-98",
                                "name": ["James Outman"],
                                "team": ["Los Angeles Dodgers"],
                                "cardAttributes": []
                            },
                            "YQ-99": {
                                "cardNumber": "YQ-99",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics"],
                                "cardAttributes": []
                            },
                            "YQ-100": {
                                "cardNumber": "YQ-100",
                                "name": ["Brayan Bello"],
                                "team": ["Boston Red Sox"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball": {
                            "89CU-1": {
                                "cardNumber": "89CU-1",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-2": {
                                "cardNumber": "89CU-2",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-3": {
                                "cardNumber": "89CU-3",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-4": {
                                "cardNumber": "89CU-4",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-5": {
                                "cardNumber": "89CU-5",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-6": {
                                "cardNumber": "89CU-6",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-7": {
                                "cardNumber": "89CU-7",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-8": {
                                "cardNumber": "89CU-8",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-9": {
                                "cardNumber": "89CU-9",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-10": {
                                "cardNumber": "89CU-10",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-11": {
                                "cardNumber": "89CU-11",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89CU-12": {
                                "cardNumber": "89CU-12",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89CU-13": {
                                "cardNumber": "89CU-13",
                                "name": ["Bo Bichette"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89CU-14": {
                                "cardNumber": "89CU-14",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89CU-15": {
                                "cardNumber": "89CU-15",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "89CU-16": {
                                "cardNumber": "89CU-16",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89CU-17": {
                                "cardNumber": "89CU-17",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "89CU-18": {
                                "cardNumber": "89CU-18",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89CU-19": {
                                "cardNumber": "89CU-19",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "89CU-20": {
                                "cardNumber": "89CU-20",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            }
                        },
                        "Future Stars": {
                            "FSU-1": {
                                "cardNumber": "FSU-1",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FSU-2": {
                                "cardNumber": "FSU-2",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FSU-3": {
                                "cardNumber": "FSU-3",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "FSU-4": {
                                "cardNumber": "FSU-4",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FSU-5": {
                                "cardNumber": "FSU-5",
                                "name": ["Justin Steele"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "FSU-6": {
                                "cardNumber": "FSU-6",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "FSU-7": {
                                "cardNumber": "FSU-7",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "FSU-8": {
                                "cardNumber": "FSU-8",
                                "name": ["Matt McLain"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "FSU-9": {
                                "cardNumber": "FSU-9",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "FSU-10": {
                                "cardNumber": "FSU-10",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "FSU-11": {
                                "cardNumber": "FSU-11",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-12": {
                                "cardNumber": "FSU-12",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-13": {
                                "cardNumber": "FSU-13",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-14": {
                                "cardNumber": "FSU-14",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-15": {
                                "cardNumber": "FSU-15",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-16": {
                                "cardNumber": "FSU-16",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-17": {
                                "cardNumber": "FSU-17",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-18": {
                                "cardNumber": "FSU-18",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-19": {
                                "cardNumber": "FSU-19",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "FSU-20": {
                                "cardNumber": "FSU-20",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Chrome All Etch": {
                            "CAEU-1": {
                                "cardNumber": "CAEU-1",
                                "name": ["Gerrit Cole"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CAEU-2": {
                                "cardNumber": "CAEU-2",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CAEU-3": {
                                "cardNumber": "CAEU-3",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CAEU-4": {
                                "cardNumber": "CAEU-4",
                                "name": ["Freddie Freeman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CAEU-5": {
                                "cardNumber": "CAEU-5",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "CAEU-6": {
                                "cardNumber": "CAEU-6",
                                "name": ["Cody Bellinger"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "CAEU-7": {
                                "cardNumber": "CAEU-7",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CAEU-8": {
                                "cardNumber": "CAEU-8",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CAEU-9": {
                                "cardNumber": "CAEU-9",
                                "name": ["Carlos Correa"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "CAEU-10": {
                                "cardNumber": "CAEU-10",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome All Etch Rookie Rush": {
                            "CAERU-1": {
                                "cardNumber": "CAERU-1",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-2": {
                                "cardNumber": "CAERU-2",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-3": {
                                "cardNumber": "CAERU-3",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-4": {
                                "cardNumber": "CAERU-4",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-5": {
                                "cardNumber": "CAERU-5",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-6": {
                                "cardNumber": "CAERU-6",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-7": {
                                "cardNumber": "CAERU-7",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-8": {
                                "cardNumber": "CAERU-8",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-9": {
                                "cardNumber": "CAERU-9",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-10": {
                                "cardNumber": "CAERU-10",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-11": {
                                "cardNumber": "CAERU-11",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-12": {
                                "cardNumber": "CAERU-12",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-13": {
                                "cardNumber": "CAERU-13",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-14": {
                                "cardNumber": "CAERU-14",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CAERU-15": {
                                "cardNumber": "CAERU-15",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Celebración": {
                            "CELE-1": {
                                "cardNumber": "CELE-1",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CELE-2": {
                                "cardNumber": "CELE-2",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CELE-3": {
                                "cardNumber": "CELE-3",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CELE-4": {
                                "cardNumber": "CELE-4",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CELE-5": {
                                "cardNumber": "CELE-5",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CELE-6": {
                                "cardNumber": "CELE-6",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CELE-7": {
                                "cardNumber": "CELE-7",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "CELE-8": {
                                "cardNumber": "CELE-8",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CELE-9": {
                                "cardNumber": "CELE-9",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CELE-10": {
                                "cardNumber": "CELE-10",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CELE-11": {
                                "cardNumber": "CELE-11",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CELE-12": {
                                "cardNumber": "CELE-12",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CELE-13": {
                                "cardNumber": "CELE-13",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CELE-14": {
                                "cardNumber": "CELE-14",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CELE-16": {
                                "cardNumber": "CELE-16",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CELE-17": {
                                "cardNumber": "CELE-17",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CELE-18": {
                                "cardNumber": "CELE-18",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CELE-19": {
                                "cardNumber": "CELE-19",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CELE-20": {
                                "cardNumber": "CELE-20",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            }
                        },
                        "Crushed": {
                            "CR-1": {
                                "cardNumber": "CR-1",
                                "name": ["Giancarlo Stanton"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CR-2": {
                                "cardNumber": "CR-2",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CR-3": {
                                "cardNumber": "CR-3",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CR-4": {
                                "cardNumber": "CR-4",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CR-5": {
                                "cardNumber": "CR-5",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CR-6": {
                                "cardNumber": "CR-6",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CR-7": {
                                "cardNumber": "CR-7",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "CR-8": {
                                "cardNumber": "CR-8",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CR-9": {
                                "cardNumber": "CR-9",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CR-10": {
                                "cardNumber": "CR-10",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CR-11": {
                                "cardNumber": "CR-11",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CR-12": {
                                "cardNumber": "CR-12",
                                "name": ["Adolis García"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CR-13": {
                                "cardNumber": "CR-13",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "CR-14": {
                                "cardNumber": "CR-14",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CR-15": {
                                "cardNumber": "CR-15",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CR-16": {
                                "cardNumber": "CR-16",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CR-17": {
                                "cardNumber": "CR-17",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CR-18": {
                                "cardNumber": "CR-18",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "CR-19": {
                                "cardNumber": "CR-19",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "CR-20": {
                                "cardNumber": "CR-20",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CR-21": {
                                "cardNumber": "CR-21",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "CR-22": {
                                "cardNumber": "CR-22",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CR-23": {
                                "cardNumber": "CR-23",
                                "name": ["Corey Seager"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CR-24": {
                                "cardNumber": "CR-24",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CR-25": {
                                "cardNumber": "CR-25",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Diamond Dominance": {
                            "DD-1": {
                                "cardNumber": "DD-1",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-2": {
                                "cardNumber": "DD-2",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-3": {
                                "cardNumber": "DD-3",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-4": {
                                "cardNumber": "DD-4",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-5": {
                                "cardNumber": "DD-5",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-6": {
                                "cardNumber": "DD-6",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-7": {
                                "cardNumber": "DD-7",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-8": {
                                "cardNumber": "DD-8",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-9": {
                                "cardNumber": "DD-9",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-10": {
                                "cardNumber": "DD-10",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "DD-11": {
                                "cardNumber": "DD-11",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "DD-12": {
                                "cardNumber": "DD-12",
                                "name": ["Mookie Betts"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "DD-13": {
                                "cardNumber": "DD-13",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "DD-14": {
                                "cardNumber": "DD-14",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "DD-15": {
                                "cardNumber": "DD-15",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "DD-16": {
                                "cardNumber": "DD-16",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "DD-17": {
                                "cardNumber": "DD-17",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "DD-18": {
                                "cardNumber": "DD-18",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "DD-19": {
                                "cardNumber": "DD-19",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "DD-20": {
                                "cardNumber": "DD-20",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "DD-21": {
                                "cardNumber": "DD-21",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "DD-22": {
                                "cardNumber": "DD-22",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "DD-23": {
                                "cardNumber": "DD-23",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "DD-24": {
                                "cardNumber": "DD-24",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "DD-25": {
                                "cardNumber": "DD-25",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            }
                        },
                        "Lets Go! Chrome": {
                            "LGC-46": {
                                "cardNumber": "LGC-46",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-47": {
                                "cardNumber": "LGC-47",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-48": {
                                "cardNumber": "LGC-48",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-49": {
                                "cardNumber": "LGC-49",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-50": {
                                "cardNumber": "LGC-50",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-51": {
                                "cardNumber": "LGC-51",
                                "name": ["Gunnar Henderson"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "LGC-52": {
                                "cardNumber": "LGC-52",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "LGC-53": {
                                "cardNumber": "LGC-53",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "LGC-54": {
                                "cardNumber": "LGC-54",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-55": {
                                "cardNumber": "LGC-55",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-56": {
                                "cardNumber": "LGC-56",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-57": {
                                "cardNumber": "LGC-57",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-58": {
                                "cardNumber": "LGC-58",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-59": {
                                "cardNumber": "LGC-59",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "LGC-60": {
                                "cardNumber": "LGC-60",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Chrome Exposé": {
                            "TCE-35": {
                                "cardNumber": "TCE-35",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-36": {
                                "cardNumber": "TCE-36",
                                "name": ["Justin Verlander"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "TCE-37": {
                                "cardNumber": "TCE-37",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-38": {
                                "cardNumber": "TCE-38",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-39": {
                                "cardNumber": "TCE-39",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-40": {
                                "cardNumber": "TCE-40",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-41": {
                                "cardNumber": "TCE-41",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-42": {
                                "cardNumber": "TCE-42",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-43": {
                                "cardNumber": "TCE-43",
                                "name": ["Austin Riley"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "TCE-44": {
                                "cardNumber": "TCE-44",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "TCE-45": {
                                "cardNumber": "TCE-45",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-46": {
                                "cardNumber": "TCE-46",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "TCE-47": {
                                "cardNumber": "TCE-47",
                                "name": ["Francisco Lindor"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "TCE-48": {
                                "cardNumber": "TCE-48",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-49": {
                                "cardNumber": "TCE-49",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-50": {
                                "cardNumber": "TCE-50",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-51": {
                                "cardNumber": "TCE-51",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-52": {
                                "cardNumber": "TCE-52",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "TCE-53": {
                                "cardNumber": "TCE-53",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "TCE-54": {
                                "cardNumber": "TCE-54",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Home Field Advantage": {
                            "HFAC-1": {
                                "cardNumber": "HFAC-1",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-2": {
                                "cardNumber": "HFAC-2",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HFAC-3": {
                                "cardNumber": "HFAC-3",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HFAC-4": {
                                "cardNumber": "HFAC-4",
                                "name": ["Andrew McCutchen"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "HFAC-5": {
                                "cardNumber": "HFAC-5",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "HFAC-6": {
                                "cardNumber": "HFAC-6",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "HFAC-7": {
                                "cardNumber": "HFAC-7",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "HFAC-8": {
                                "cardNumber": "HFAC-8",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "HFAC-9": {
                                "cardNumber": "HFAC-9",
                                "name": ["Brandon Lowe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "HFAC-10": {
                                "cardNumber": "HFAC-10",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "HFAC-11": {
                                "cardNumber": "HFAC-11",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HFAC-12": {
                                "cardNumber": "HFAC-12",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-13": {
                                "cardNumber": "HFAC-13",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-14": {
                                "cardNumber": "HFAC-14",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-15": {
                                "cardNumber": "HFAC-15",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-16": {
                                "cardNumber": "HFAC-16",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-17": {
                                "cardNumber": "HFAC-17",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "HFAC-18": {
                                "cardNumber": "HFAC-18",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-19": {
                                "cardNumber": "HFAC-19",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-20": {
                                "cardNumber": "HFAC-20",
                                "name": ["Sandy Alcantara"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": []
                            },
                            "HFAC-21": {
                                "cardNumber": "HFAC-21",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-22": {
                                "cardNumber": "HFAC-22",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "HFAC-23": {
                                "cardNumber": "HFAC-23",
                                "name": ["Royce Lewis"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "HFAC-24": {
                                "cardNumber": "HFAC-24",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "HFAC-25": {
                                "cardNumber": "HFAC-25",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-26": {
                                "cardNumber": "HFAC-26",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-27": {
                                "cardNumber": "HFAC-27",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-28": {
                                "cardNumber": "HFAC-28",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-29": {
                                "cardNumber": "HFAC-29",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-30": {
                                "cardNumber": "HFAC-30",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "HFAC-31": {
                                "cardNumber": "HFAC-31",
                                "name": ["Nolan Jones"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "HFAC-32": {
                                "cardNumber": "HFAC-32",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-33": {
                                "cardNumber": "HFAC-33",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-34": {
                                "cardNumber": "HFAC-34",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-35": {
                                "cardNumber": "HFAC-35",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-36": {
                                "cardNumber": "HFAC-36",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-37": {
                                "cardNumber": "HFAC-37",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HFAC-38": {
                                "cardNumber": "HFAC-38",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "HFAC-39": {
                                "cardNumber": "HFAC-39",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HFAC-40": {
                                "cardNumber": "HFAC-40",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            }
                        },
                        "Topps Chrome Radiating Rookies": {
                            "RR-21": {
                                "cardNumber": "RR-21",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-22": {
                                "cardNumber": "RR-22",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-23": {
                                "cardNumber": "RR-23",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-24": {
                                "cardNumber": "RR-24",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-25": {
                                "cardNumber": "RR-25",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-26": {
                                "cardNumber": "RR-26",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-27": {
                                "cardNumber": "RR-27",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-28": {
                                "cardNumber": "RR-28",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-29": {
                                "cardNumber": "RR-29",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RR-30": {
                                "cardNumber": "RR-30",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Helix": {
                            "HX-16": {
                                "cardNumber": "HX-16",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HX-17": {
                                "cardNumber": "HX-17",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "HX-18": {
                                "cardNumber": "HX-18",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HX-19": {
                                "cardNumber": "HX-19",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HX-20": {
                                "cardNumber": "HX-20",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "HX-21": {
                                "cardNumber": "HX-21",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "HX-22": {
                                "cardNumber": "HX-22",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "HX-23": {
                                "cardNumber": "HX-23",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "HX-24": {
                                "cardNumber": "HX-24",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "HX-25": {
                                "cardNumber": "HX-25",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            }
                        },
                        "Major League Minis": {
                            "MM-1": {
                                "cardNumber": "MM-1",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MM-2": {
                                "cardNumber": "MM-2",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MM-3": {
                                "cardNumber": "MM-3",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MM-4": {
                                "cardNumber": "MM-4",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "MM-5": {
                                "cardNumber": "MM-5",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MM-6": {
                                "cardNumber": "MM-6",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MM-7": {
                                "cardNumber": "MM-7",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MM-8": {
                                "cardNumber": "MM-8",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MM-9": {
                                "cardNumber": "MM-9",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MM-10": {
                                "cardNumber": "MM-10",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MM-11": {
                                "cardNumber": "MM-11",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MM-12": {
                                "cardNumber": "MM-12",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MM-13": {
                                "cardNumber": "MM-13",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MM-14": {
                                "cardNumber": "MM-14",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MM-15": {
                                "cardNumber": "MM-15",
                                "name": ["Bryce Harper"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            }
                        },
                        "Sizzle": {
                            "SIZ-1": {
                                "cardNumber": "SIZ-1",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SIZ-2": {
                                "cardNumber": "SIZ-2",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SIZ-3": {
                                "cardNumber": "SIZ-3",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SIZ-4": {
                                "cardNumber": "SIZ-4",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SIZ-5": {
                                "cardNumber": "SIZ-5",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SIZ-6": {
                                "cardNumber": "SIZ-6",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SIZ-7": {
                                "cardNumber": "SIZ-7",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "SIZ-8": {
                                "cardNumber": "SIZ-8",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "SIZ-9": {
                                "cardNumber": "SIZ-9",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "SIZ-10": {
                                "cardNumber": "SIZ-10",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            }
                        },
                        "MLB Illustrations": {
                            "MLBI-1": {
                                "cardNumber": "MLBI-1",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLBI-2": {
                                "cardNumber": "MLBI-2",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "MLBI-3": {
                                "cardNumber": "MLBI-3",
                                "name": ["Ken Griffey Jr."],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "MLBI-4": {
                                "cardNumber": "MLBI-4",
                                "name": ["Shohei Ohtani"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "MLBI-5": {
                                "cardNumber": "MLBI-5",
                                "name": ["Mike Trout"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "MLBI-6": {
                                "cardNumber": "MLBI-6",
                                "name": ["Aaron Judge"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "MLBI-7": {
                                "cardNumber": "MLBI-7",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "MLBI-8": {
                                "cardNumber": "MLBI-8",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "MLBI-9": {
                                "cardNumber": "MLBI-9",
                                "name": ["Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "MLBI-10": {
                                "cardNumber": "MLBI-10",
                                "name": ["Frank Thomas"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            }
                        }
                    },
                    "Autograph Relic": {
                        "MLB Rookie Debut Patch Autographs": {
                            "RDPA-AA": {
                                "cardNumber": "RDPA-AA",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AB": {
                                "cardNumber": "RDPA-AB",
                                "name": ["Addison Barger"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AC": {
                                "cardNumber": "RDPA-AC",
                                "name": ["Alexander Canario"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AF": {
                                "cardNumber": "RDPA-AF",
                                "name": ["Angel Felipe"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AJ": {
                                "cardNumber": "RDPA-AJ",
                                "name": ["Alek Jacob"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AJS": {
                                "cardNumber": "RDPA-AJS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AL": {
                                "cardNumber": "RDPA-AL",
                                "name": ["Andre Lipcius"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AM": {
                                "cardNumber": "RDPA-AM",
                                "name": ["Anthony Maldonado"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AM2": {
                                "cardNumber": "RDPA-AM2",
                                "name": ["Alec Marsh"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AMO": {
                                "cardNumber": "RDPA-AMO",
                                "name": ["Anthony Molina"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AP": {
                                "cardNumber": "RDPA-AP",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AS": {
                                "cardNumber": "RDPA-AS",
                                "name": ["Austin Shenton"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AS2": {
                                "cardNumber": "RDPA-AS2",
                                "name": ["Andrew Saalfrank"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ASP": {
                                "cardNumber": "RDPA-ASP",
                                "name": ["Alex Speas"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AU": {
                                "cardNumber": "RDPA-AU",
                                "name": ["Abner Uribe"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AV": {
                                "cardNumber": "RDPA-AV",
                                "name": ["Anthony Veneziano"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AW": {
                                "cardNumber": "RDPA-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AWI": {
                                "cardNumber": "RDPA-AWI",
                                "name": ["Amos Willingham"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AWL": {
                                "cardNumber": "RDPA-AWL",
                                "name": ["Alika Williams"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-AWS": {
                                "cardNumber": "RDPA-AWS",
                                "name": ["Allan Winans"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BA": {
                                "cardNumber": "RDPA-BA",
                                "name": ["Blaze Alexander"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BB": {
                                "cardNumber": "RDPA-BB",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BH": {
                                "cardNumber": "RDPA-BH",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BH2": {
                                "cardNumber": "RDPA-BH2",
                                "name": ["Brenan Hanifee"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BHE": {
                                "cardNumber": "RDPA-BHE",
                                "name": ["Blair Henley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BHU": {
                                "cardNumber": "RDPA-BHU",
                                "name": ["Bryan Hudson"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BJ": {
                                "cardNumber": "RDPA-BJ",
                                "name": ["Bryce Jarvis"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BR": {
                                "cardNumber": "RDPA-BR",
                                "name": ["Bryan Ramos"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BW": {
                                "cardNumber": "RDPA-BW",
                                "name": ["Blake Walston"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BW2": {
                                "cardNumber": "RDPA-BW2",
                                "name": ["Brandon Walter"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BWH": {
                                "cardNumber": "RDPA-BWH",
                                "name": ["Brendan White"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-BWO": {
                                "cardNumber": "RDPA-BWO",
                                "name": ["Bryan Woo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CA": {
                                "cardNumber": "RDPA-CA",
                                "name": ["Clayton Andrews"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CB": {
                                "cardNumber": "RDPA-CB",
                                "name": ["Cam Booser"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CB2": {
                                "cardNumber": "RDPA-CB2",
                                "name": ["Caleb Boushley"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CBE": {
                                "cardNumber": "RDPA-CBE",
                                "name": ["Clayton Beeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CC": {
                                "cardNumber": "RDPA-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CE": {
                                "cardNumber": "RDPA-CE",
                                "name": ["Cam Eden"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CES": {
                                "cardNumber": "RDPA-CES",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CK": {
                                "cardNumber": "RDPA-CK",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CK2": {
                                "cardNumber": "RDPA-CK2",
                                "name": ["Connor Kaiser"],
                                "team": ["Colorado Rockies®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CM": {
                                "cardNumber": "RDPA-CM",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CMA": {
                                "cardNumber": "RDPA-CMA",
                                "name": ["Cade Marlowe"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CML": {
                                "cardNumber": "RDPA-CML",
                                "name": ["Carmen Mlodzinski"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CMO": {
                                "cardNumber": "RDPA-CMO",
                                "name": ["Coco Montes"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CMU": {
                                "cardNumber": "RDPA-CMU",
                                "name": ["Chris Murphy"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CP": {
                                "cardNumber": "RDPA-CP",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CR": {
                                "cardNumber": "RDPA-CR",
                                "name": ["Chris Roller"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CR2": {
                                "cardNumber": "RDPA-CR2",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CRO": {
                                "cardNumber": "RDPA-CRO",
                                "name": ["Chris Roycroft"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CS": {
                                "cardNumber": "RDPA-CS",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CS2": {
                                "cardNumber": "RDPA-CS2",
                                "name": ["Chandler Seagle"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CSE": {
                                "cardNumber": "RDPA-CSE",
                                "name": ["Colin Selby"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CSM": {
                                "cardNumber": "RDPA-CSM",
                                "name": ["Cade Smith"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CSP": {
                                "cardNumber": "RDPA-CSP",
                                "name": ["Carson Spiers"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CV": {
                                "cardNumber": "RDPA-CV",
                                "name": ["Chris Vallimont"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-CW": {
                                "cardNumber": "RDPA-CW",
                                "name": ["Cole Winn"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DB": {
                                "cardNumber": "RDPA-DB",
                                "name": ["David Banuelos"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DC": {
                                "cardNumber": "RDPA-DC",
                                "name": ["Declan Cronin"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DCA": {
                                "cardNumber": "RDPA-DCA",
                                "name": ["Dominic Canzone"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DD": {
                                "cardNumber": "RDPA-DD",
                                "name": ["Davis Daniel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DH": {
                                "cardNumber": "RDPA-DH",
                                "name": ["Darell Hernaiz"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DH2": {
                                "cardNumber": "RDPA-DH2",
                                "name": ["Daysbel Hernández"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DHA": {
                                "cardNumber": "RDPA-DHA",
                                "name": ["David Hamilton"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DM": {
                                "cardNumber": "RDPA-DM",
                                "name": ["Drew Millas"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DMY": {
                                "cardNumber": "RDPA-DMY",
                                "name": ["Dane Myers"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DN": {
                                "cardNumber": "RDPA-DN",
                                "name": ["Dedniel Núñez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DP": {
                                "cardNumber": "RDPA-DP",
                                "name": ["Daniel Palencia"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DR": {
                                "cardNumber": "RDPA-DR",
                                "name": ["Drew Rom"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DS": {
                                "cardNumber": "RDPA-DS",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DSW": {
                                "cardNumber": "RDPA-DSW",
                                "name": ["Devin Sweet"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DV": {
                                "cardNumber": "RDPA-DV",
                                "name": ["Darius Vines"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-DW": {
                                "cardNumber": "RDPA-DW",
                                "name": ["Davis Wendzel"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EC": {
                                "cardNumber": "RDPA-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ED": {
                                "cardNumber": "RDPA-ED",
                                "name": ["Enmanuel De Jesus"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EDLC": {
                                "cardNumber": "RDPA-EDLC",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EH": {
                                "cardNumber": "RDPA-EH",
                                "name": ["Emerson Hancock"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EJ": {
                                "cardNumber": "RDPA-EJ",
                                "name": ["Evan Justice"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EL": {
                                "cardNumber": "RDPA-EL",
                                "name": ["Easton Lucas"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EM": {
                                "cardNumber": "RDPA-EM",
                                "name": ["Erik Miller"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EN": {
                                "cardNumber": "RDPA-EN",
                                "name": ["Edgar Navarro"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EP": {
                                "cardNumber": "RDPA-EP",
                                "name": ["Everson Pereira"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ER": {
                                "cardNumber": "RDPA-ER",
                                "name": ["Emmanuel Ramirez"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ER2": {
                                "cardNumber": "RDPA-ER2",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ES": {
                                "cardNumber": "RDPA-ES",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-EV": {
                                "cardNumber": "RDPA-EV",
                                "name": ["Eli Villalobos"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-FW": {
                                "cardNumber": "RDPA-FW",
                                "name": ["Forrest Whitley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-FW2": {
                                "cardNumber": "RDPA-FW2",
                                "name": ["Forrest Wall"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-GH": {
                                "cardNumber": "RDPA-GH",
                                "name": ["Grant Hartwig"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-GK": {
                                "cardNumber": "RDPA-GK",
                                "name": ["Grae Kessinger"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-GP": {
                                "cardNumber": "RDPA-GP",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-GW": {
                                "cardNumber": "RDPA-GW",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-HAD": {
                                "cardNumber": "RDPA-HAD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-HD": {
                                "cardNumber": "RDPA-HD",
                                "name": ["Hagen Danner"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-HG": {
                                "cardNumber": "RDPA-HG",
                                "name": ["Hunter Goodman"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-HK": {
                                "cardNumber": "RDPA-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-HS": {
                                "cardNumber": "RDPA-HS",
                                "name": ["Hunter Stratton"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-IC": {
                                "cardNumber": "RDPA-IC",
                                "name": ["Isaiah Campbell"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-IL": {
                                "cardNumber": "RDPA-IL",
                                "name": ["Irving Lopez"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JA": {
                                "cardNumber": "RDPA-JA",
                                "name": ["Jordyn Adams"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JAM": {
                                "cardNumber": "RDPA-JAM",
                                "name": ["Jacob Amaya"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JB": {
                                "cardNumber": "RDPA-JB",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JB2": {
                                "cardNumber": "RDPA-JB2",
                                "name": ["Jonathan Bowlan"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JBA": {
                                "cardNumber": "RDPA-JBA",
                                "name": ["Jorge Barrosa"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JBA2": {
                                "cardNumber": "RDPA-JBA2",
                                "name": ["Jordan Balazovic"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JBO": {
                                "cardNumber": "RDPA-JBO",
                                "name": ["Joey Boyle"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JC": {
                                "cardNumber": "RDPA-JC",
                                "name": ["Jonathan Cannon"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JC2": {
                                "cardNumber": "RDPA-JC2",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JCA": {
                                "cardNumber": "RDPA-JCA",
                                "name": ["Jair Camargo"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JCH": {
                                "cardNumber": "RDPA-JCH",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JCL": {
                                "cardNumber": "RDPA-JCL",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JD": {
                                "cardNumber": "RDPA-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JDE": {
                                "cardNumber": "RDPA-JDE",
                                "name": ["Jonny Deluca"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JE": {
                                "cardNumber": "RDPA-JE",
                                "name": ["Jose Espada"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JES": {
                                "cardNumber": "RDPA-JES",
                                "name": ["Joey Estes"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JF": {
                                "cardNumber": "RDPA-JF",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JF2": {
                                "cardNumber": "RDPA-JF2",
                                "name": ["José Fermín"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JFE": {
                                "cardNumber": "RDPA-JFE",
                                "name": ["Jose Ferrer"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JH": {
                                "cardNumber": "RDPA-JH",
                                "name": ["Jacob Hurtubise"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JHL": {
                                "cardNumber": "RDPA-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JHO": {
                                "cardNumber": "RDPA-JHO",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JJ": {
                                "cardNumber": "RDPA-JJ",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JJ2": {
                                "cardNumber": "RDPA-JJ2",
                                "name": ["Joe Jacques"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JL": {
                                "cardNumber": "RDPA-JL",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JL2": {
                                "cardNumber": "RDPA-JL2",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JLE": {
                                "cardNumber": "RDPA-JLE",
                                "name": ["Jordan Leasure"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JLO": {
                                "cardNumber": "RDPA-JLO",
                                "name": ["José Lopez"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JLR": {
                                "cardNumber": "RDPA-JLR",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JLZ": {
                                "cardNumber": "RDPA-JLZ",
                                "name": ["Jacob Lopez"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JM": {
                                "cardNumber": "RDPA-JM",
                                "name": ["Josh Maciejewski"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JM2": {
                                "cardNumber": "RDPA-JM2",
                                "name": ["J.P. Martínez"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JMA": {
                                "cardNumber": "RDPA-JMA",
                                "name": ["Justin Martinez"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JMC": {
                                "cardNumber": "RDPA-JMC",
                                "name": ["James McArthur"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JME": {
                                "cardNumber": "RDPA-JME",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JO": {
                                "cardNumber": "RDPA-JO",
                                "name": ["Jonathan Ornelas"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JP": {
                                "cardNumber": "RDPA-JP",
                                "name": ["Jhonny Pereda"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JR": {
                                "cardNumber": "RDPA-JR",
                                "name": ["Johnathan Rodriguez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JR2": {
                                "cardNumber": "RDPA-JR2",
                                "name": ["Jackson Rutledge"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JRO": {
                                "cardNumber": "RDPA-JRO",
                                "name": ["José Rodríguez"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JRS": {
                                "cardNumber": "RDPA-JRS",
                                "name": ["Johan Rojas"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JS": {
                                "cardNumber": "RDPA-JS",
                                "name": ["Justin Slaten"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JS2": {
                                "cardNumber": "RDPA-JS2",
                                "name": ["José Soriano"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JT": {
                                "cardNumber": "RDPA-JT",
                                "name": ["José Tena"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JTR": {
                                "cardNumber": "RDPA-JTR",
                                "name": ["Jared Triolo"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JW": {
                                "cardNumber": "RDPA-JW",
                                "name": ["Jackson Wolf"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JWE": {
                                "cardNumber": "RDPA-JWE",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JWO": {
                                "cardNumber": "RDPA-JWO",
                                "name": ["Jake Wong"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-JY": {
                                "cardNumber": "RDPA-JY",
                                "name": ["Jacob Young"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KC": {
                                "cardNumber": "RDPA-KC",
                                "name": ["Kelvin Caceres"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KF": {
                                "cardNumber": "RDPA-KF",
                                "name": ["Kody Funderburk"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KH": {
                                "cardNumber": "RDPA-KH",
                                "name": ["Kyle Hurt"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KI": {
                                "cardNumber": "RDPA-KI",
                                "name": ["Kolton Ingram"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KM": {
                                "cardNumber": "RDPA-KM",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KMC": {
                                "cardNumber": "RDPA-KMC",
                                "name": ["Kyle McCann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KN": {
                                "cardNumber": "RDPA-KN",
                                "name": ["Kyle Nicolas"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KP": {
                                "cardNumber": "RDPA-KP",
                                "name": ["Kyren Paris"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KT": {
                                "cardNumber": "RDPA-KT",
                                "name": ["Kai-Wei Teng"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-KW": {
                                "cardNumber": "RDPA-KW",
                                "name": ["Keaton Winn"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LB": {
                                "cardNumber": "RDPA-LB",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LBA": {
                                "cardNumber": "RDPA-LBA",
                                "name": ["Luken Baker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LK": {
                                "cardNumber": "RDPA-LK",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LL": {
                                "cardNumber": "RDPA-LL",
                                "name": ["Luke Little"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LM": {
                                "cardNumber": "RDPA-LM",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LR": {
                                "cardNumber": "RDPA-LR",
                                "name": ["Leo Rivas"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LR2": {
                                "cardNumber": "RDPA-LR2",
                                "name": ["Lane Ramsey"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LRI": {
                                "cardNumber": "RDPA-LRI",
                                "name": ["Lyon Richardson"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LRO": {
                                "cardNumber": "RDPA-LRO",
                                "name": ["Landen Roupp"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-LV": {
                                "cardNumber": "RDPA-LV",
                                "name": ["Luis Vázquez"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MB": {
                                "cardNumber": "RDPA-MB",
                                "name": ["Mason Black"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MK": {
                                "cardNumber": "RDPA-MK",
                                "name": ["Matt Krook"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ML": {
                                "cardNumber": "RDPA-ML",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MM": {
                                "cardNumber": "RDPA-MM",
                                "name": ["Mason McCoy"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MP": {
                                "cardNumber": "RDPA-MP",
                                "name": ["Mitchell Parker"],
                                "team": ["Washington Nationals®", "1/1"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RDPA-MS": {
                                "cardNumber": "RDPA-MS",
                                "name": ["Max Schuemann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MSA": {
                                "cardNumber": "RDPA-MSA",
                                "name": ["Matt Sauer"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MSP": {
                                "cardNumber": "RDPA-MSP",
                                "name": ["Mitch Spence"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MW": {
                                "cardNumber": "RDPA-MW",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-MWA": {
                                "cardNumber": "RDPA-MWA",
                                "name": ["Matt Waldron"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NA": {
                                "cardNumber": "RDPA-NA",
                                "name": ["Nick Avila"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NG": {
                                "cardNumber": "RDPA-NG",
                                "name": ["Nick Gonzales"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NH": {
                                "cardNumber": "RDPA-NH",
                                "name": ["Nick Hernandez"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NL": {
                                "cardNumber": "RDPA-NL",
                                "name": ["Nick Loftin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NM": {
                                "cardNumber": "RDPA-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NN": {
                                "cardNumber": "RDPA-NN",
                                "name": ["Nick Nastrini"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NNU": {
                                "cardNumber": "RDPA-NNU",
                                "name": ["Nasim Nuñez"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NR": {
                                "cardNumber": "RDPA-NR",
                                "name": ["Nick Robertson"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NS": {
                                "cardNumber": "RDPA-NS",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-NU": {
                                "cardNumber": "RDPA-NU",
                                "name": ["Naoyuki Uwasawa"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-OB": {
                                "cardNumber": "RDPA-OB",
                                "name": ["Osleivis Basabe"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-OBI": {
                                "cardNumber": "RDPA-OBI",
                                "name": ["Osvaldo Bido"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-OD": {
                                "cardNumber": "RDPA-OD",
                                "name": ["Oliver Dunn"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-OK": {
                                "cardNumber": "RDPA-OK",
                                "name": ["Orion Kerkering"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-OW": {
                                "cardNumber": "RDPA-OW",
                                "name": ["Owen White"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-PB": {
                                "cardNumber": "RDPA-PB",
                                "name": ["Prelander Berroa"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-PC": {
                                "cardNumber": "RDPA-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-PH": {
                                "cardNumber": "RDPA-PH",
                                "name": ["Porter Hodge"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-PM": {
                                "cardNumber": "RDPA-PM",
                                "name": ["Parker Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-PP": {
                                "cardNumber": "RDPA-PP",
                                "name": ["Pedro Pagés"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-PS": {
                                "cardNumber": "RDPA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-QP": {
                                "cardNumber": "RDPA-QP",
                                "name": ["Quinn Priester"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RF": {
                                "cardNumber": "RDPA-RF",
                                "name": ["Ryan Fernandez"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RG": {
                                "cardNumber": "RDPA-RG",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RG2": {
                                "cardNumber": "RDPA-RG2",
                                "name": ["Robert Garcia"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RK": {
                                "cardNumber": "RDPA-RK",
                                "name": ["Ricky Karcher"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RM": {
                                "cardNumber": "RDPA-RM",
                                "name": ["Roddery Muñoz"],
                                "team": ["Miami Marlins®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RM2": {
                                "cardNumber": "RDPA-RM2",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RO": {
                                "cardNumber": "RDPA-RO",
                                "name": ["Reese Olson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RR": {
                                "cardNumber": "RDPA-RR",
                                "name": ["Randy Rodríguez"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RR2": {
                                "cardNumber": "RDPA-RR2",
                                "name": ["Ryder Ryan"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RV": {
                                "cardNumber": "RDPA-RV",
                                "name": ["Ricky Vanasco"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-RW": {
                                "cardNumber": "RDPA-RW",
                                "name": ["Randy Wynne"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SA": {
                                "cardNumber": "RDPA-SA",
                                "name": ["Spencer Arrighetti"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SC": {
                                "cardNumber": "RDPA-SC",
                                "name": ["Steven Cruz"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SCE": {
                                "cardNumber": "RDPA-SCE",
                                "name": ["Slade Cecconi"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SD": {
                                "cardNumber": "RDPA-SD",
                                "name": ["Shawn Dubin"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SF": {
                                "cardNumber": "RDPA-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SG": {
                                "cardNumber": "RDPA-SG",
                                "name": ["Sawyer Gipson-Long"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SH": {
                                "cardNumber": "RDPA-SH",
                                "name": ["Spencer Horwitz"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SI": {
                                "cardNumber": "RDPA-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-SK": {
                                "cardNumber": "RDPA-SK",
                                "name": ["Stephen Kolek"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ST": {
                                "cardNumber": "RDPA-ST",
                                "name": ["Samad Taylor"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TA": {
                                "cardNumber": "RDPA-TA",
                                "name": ["Ty Adcock"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TB": {
                                "cardNumber": "RDPA-TB",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TC": {
                                "cardNumber": "RDPA-TC",
                                "name": ["Trey Cabbage"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TF": {
                                "cardNumber": "RDPA-TF",
                                "name": ["Tyler Ferguson"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TF2": {
                                "cardNumber": "RDPA-TF2",
                                "name": ["Tyler Fitzgerald"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TG": {
                                "cardNumber": "RDPA-TG",
                                "name": ["Tristan Gray"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TH": {
                                "cardNumber": "RDPA-TH",
                                "name": ["TJ Hopkins"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TJ": {
                                "cardNumber": "RDPA-TJ",
                                "name": ["Tyler Jay"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TK": {
                                "cardNumber": "RDPA-TK",
                                "name": ["Taylor Kohlwey"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TL": {
                                "cardNumber": "RDPA-TL",
                                "name": ["Trey Lipscomb"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TM": {
                                "cardNumber": "RDPA-TM",
                                "name": ["Tobias Myers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-TS": {
                                "cardNumber": "RDPA-TS",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-VM": {
                                "cardNumber": "RDPA-VM",
                                "name": ["Victor Mederos"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-VS": {
                                "cardNumber": "RDPA-VS",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-VV": {
                                "cardNumber": "RDPA-VV",
                                "name": ["Victor Vodnik"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-WA": {
                                "cardNumber": "RDPA-WA",
                                "name": ["Wilyer Abreu"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-WK": {
                                "cardNumber": "RDPA-WK",
                                "name": ["Will Klein"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-WL": {
                                "cardNumber": "RDPA-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-WM": {
                                "cardNumber": "RDPA-WM",
                                "name": ["Wade Meckler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-WP": {
                                "cardNumber": "RDPA-WP",
                                "name": ["Wenceel Pérez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-WW": {
                                "cardNumber": "RDPA-WW",
                                "name": ["Weston Wilson"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-YG": {
                                "cardNumber": "RDPA-YG",
                                "name": ["Yoendrys Gomez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-YM": {
                                "cardNumber": "RDPA-YM",
                                "name": ["Yuki Matsui"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-YR": {
                                "cardNumber": "RDPA-YR",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-YY": {
                                "cardNumber": "RDPA-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ZD": {
                                "cardNumber": "RDPA-ZD",
                                "name": ["Zach DeLoach"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ZG": {
                                "cardNumber": "RDPA-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ZK": {
                                "cardNumber": "RDPA-ZK",
                                "name": ["Zac Kristofak"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            },
                            "RDPA-ZR": {
                                "cardNumber": "RDPA-ZR",
                                "name": ["Zach Remillard"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie", "1/1"]
                            }
                        }
                    },
                    "Autograph": {
                        "Topps Chrome Update Autograph Cards": {
                            "AC-AA": {
                                "cardNumber": "AC-AA",
                                "name": ["Andrew Abbott"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-AC": {
                                "cardNumber": "AC-AC",
                                "name": ["Alexander Canario"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-AS": {
                                "cardNumber": "AC-AS",
                                "name": ["AJ Smith-Shawver"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-ASH": {
                                "cardNumber": "AC-ASH",
                                "name": ["Austin Shenton"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-AV": {
                                "cardNumber": "AC-AV",
                                "name": ["Alex Verdugo"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AC-AWE": {
                                "cardNumber": "AC-AWE",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-BHA": {
                                "cardNumber": "AC-BHA",
                                "name": ["Brett Harris"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-BS": {
                                "cardNumber": "AC-BS",
                                "name": ["Brady Singer"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "AC-BW": {
                                "cardNumber": "AC-BW",
                                "name": ["Bryan Woo"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CB": {
                                "cardNumber": "AC-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "AC-CC": {
                                "cardNumber": "AC-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CE": {
                                "cardNumber": "AC-CE",
                                "name": ["Christian Encarnacion-Strand"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CHO": {
                                "cardNumber": "AC-CHO",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AC-CK": {
                                "cardNumber": "AC-CK",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CME": {
                                "cardNumber": "AC-CME",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CP": {
                                "cardNumber": "AC-CP",
                                "name": ["Connor Phillips"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CR": {
                                "cardNumber": "AC-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CRA": {
                                "cardNumber": "AC-CRA",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "AC-CRR": {
                                "cardNumber": "AC-CRR",
                                "name": ["Chris Roller"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CS": {
                                "cardNumber": "AC-CS",
                                "name": ["Cade Smith"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-CSC": {
                                "cardNumber": "AC-CSC",
                                "name": ["Christian Scott"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-DB": {
                                "cardNumber": "AC-DB",
                                "name": ["David Bañuelos"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-DHA": {
                                "cardNumber": "AC-DHA",
                                "name": ["David Hamilton"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-DS": {
                                "cardNumber": "AC-DS",
                                "name": ["Davis Schneider"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-EH": {
                                "cardNumber": "AC-EH",
                                "name": ["Emerson Hancock"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-EP": {
                                "cardNumber": "AC-EP",
                                "name": ["Everson Pereira"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-ER": {
                                "cardNumber": "AC-ER",
                                "name": ["Endy Rodríguez"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-ET": {
                                "cardNumber": "AC-ET",
                                "name": ["Ezequiel Tovar"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "AC-FWH": {
                                "cardNumber": "AC-FWH",
                                "name": ["Forrest Whitley"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-GK": {
                                "cardNumber": "AC-GK",
                                "name": ["Grae Kessinger"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-GR": {
                                "cardNumber": "AC-GR",
                                "name": ["Grayson Rodriguez"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "AC-GRP": {
                                "cardNumber": "AC-GRP",
                                "name": ["Graham Pauley"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-GW": {
                                "cardNumber": "AC-GW",
                                "name": ["Gavin Williams"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-HB": {
                                "cardNumber": "AC-HB",
                                "name": ["Harrison Bader"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "AC-HD": {
                                "cardNumber": "AC-HD",
                                "name": ["Henry Davis"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-HK": {
                                "cardNumber": "AC-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JAC": {
                                "cardNumber": "AC-JAC",
                                "name": ["Jair Camargo"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JB": {
                                "cardNumber": "AC-JB",
                                "name": ["JJ Bleday"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "AC-JC": {
                                "cardNumber": "AC-JC",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JCA": {
                                "cardNumber": "AC-JCA",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JCL": {
                                "cardNumber": "AC-JCL",
                                "name": ["Jonatan Clase"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JD": {
                                "cardNumber": "AC-JD",
                                "name": ["Jonny Deluca"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JH": {
                                "cardNumber": "AC-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JHU": {
                                "cardNumber": "AC-JHU",
                                "name": ["Jacob Hurtubise"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JL": {
                                "cardNumber": "AC-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JM": {
                                "cardNumber": "AC-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JO": {
                                "cardNumber": "AC-JO",
                                "name": ["Joey Ortiz"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "AC-JOE": {
                                "cardNumber": "AC-JOE",
                                "name": ["Joey Loperfido"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JOR": {
                                "cardNumber": "AC-JOR",
                                "name": ["Jordan Beck"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JOU": {
                                "cardNumber": "AC-JOU",
                                "name": ["James Outman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "AC-JST": {
                                "cardNumber": "AC-JST",
                                "name": ["Justin Steele"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "AC-JUF": {
                                "cardNumber": "AC-JUF",
                                "name": ["Justin Foscue"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JW": {
                                "cardNumber": "AC-JW",
                                "name": ["Jordan Wicks"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JWE": {
                                "cardNumber": "AC-JWE",
                                "name": ["Jordan Westburg"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-JY": {
                                "cardNumber": "AC-JY",
                                "name": ["Jacob Young"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-KB": {
                                "cardNumber": "AC-KB",
                                "name": ["Kyle Bradish"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "AC-KCA": {
                                "cardNumber": "AC-KCA",
                                "name": ["Kerry Carpenter"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "AC-KH": {
                                "cardNumber": "AC-KH",
                                "name": ["Kyle Harrison"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-KHU": {
                                "cardNumber": "AC-KHU",
                                "name": ["Kyle Hurt"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-KMC": {
                                "cardNumber": "AC-KMC",
                                "name": ["Kyle McCann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-KP": {
                                "cardNumber": "AC-KP",
                                "name": ["Kyren Paris"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-LB": {
                                "cardNumber": "AC-LB",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-LG": {
                                "cardNumber": "AC-LG",
                                "name": ["Luis Gil"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AC-LK": {
                                "cardNumber": "AC-LK",
                                "name": ["Landon Knack"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-LM": {
                                "cardNumber": "AC-LM",
                                "name": ["Luis Matos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-LO": {
                                "cardNumber": "AC-LO",
                                "name": ["Logan O'Hoppe"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "AC-LV": {
                                "cardNumber": "AC-LV",
                                "name": ["Luis Vázquez"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-MB": {
                                "cardNumber": "AC-MB",
                                "name": ["Mason Black"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-MG": {
                                "cardNumber": "AC-MG",
                                "name": ["Maikel Garcia"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "AC-MH": {
                                "cardNumber": "AC-MH",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "AC-MIB": {
                                "cardNumber": "AC-MIB",
                                "name": ["Michael Busch"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "AC-ML": {
                                "cardNumber": "AC-ML",
                                "name": ["Marco Luciano"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-MM": {
                                "cardNumber": "AC-MM",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "AC-MSC": {
                                "cardNumber": "AC-MSC",
                                "name": ["Max Schuemann"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-NG": {
                                "cardNumber": "AC-NG",
                                "name": ["Nick Gonzales"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-NL": {
                                "cardNumber": "AC-NL",
                                "name": ["Nick Loftin"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-NM": {
                                "cardNumber": "AC-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-NS": {
                                "cardNumber": "AC-NS",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-OC": {
                                "cardNumber": "AC-OC",
                                "name": ["Oswaldo Cabrera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "AC-PB": {
                                "cardNumber": "AC-PB",
                                "name": ["Patrick Bailey"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "AC-PC": {
                                "cardNumber": "AC-PC",
                                "name": ["Pete Crow-Armstrong"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-PH": {
                                "cardNumber": "AC-PH",
                                "name": ["Porter Hodge"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-PM": {
                                "cardNumber": "AC-PM",
                                "name": ["Parker Meadows"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-PP": {
                                "cardNumber": "AC-PP",
                                "name": ["Pedro Pagés"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-QP": {
                                "cardNumber": "AC-QP",
                                "name": ["Quinn Priester"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-RD": {
                                "cardNumber": "AC-RD",
                                "name": ["Reid Detmers"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "AC-REES": {
                                "cardNumber": "AC-REES",
                                "name": ["Reese Olson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-RH": {
                                "cardNumber": "AC-RH",
                                "name": ["Rhys Hoskins"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "AC-RJ": {
                                "cardNumber": "AC-RJ",
                                "name": ["Ryan Jeffers"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "AC-RM": {
                                "cardNumber": "AC-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-ROBL": {
                                "cardNumber": "AC-ROBL",
                                "name": ["Ronel Blanco"],
                                "team": ["Houston Astros™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-RP": {
                                "cardNumber": "AC-RP",
                                "name": ["Ryan Pepiot"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "AC-RRA": {
                                "cardNumber": "AC-RRA",
                                "name": ["Robbie Ray"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "AC-SF": {
                                "cardNumber": "AC-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-SGR": {
                                "cardNumber": "AC-SGR",
                                "name": ["Sonny Gray"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "AC-SH": {
                                "cardNumber": "AC-SH",
                                "name": ["Spencer Horwitz"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-SI": {
                                "cardNumber": "AC-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-TBI": {
                                "cardNumber": "AC-TBI",
                                "name": ["Tanner Bibee"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "AC-TBY": {
                                "cardNumber": "AC-TBY",
                                "name": ["Taj Bradley"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": []
                            },
                            "AC-TF": {
                                "cardNumber": "AC-TF",
                                "name": ["Tyler Fitzgerald"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-TM": {
                                "cardNumber": "AC-TM",
                                "name": ["Tobias Myers"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-TRL": {
                                "cardNumber": "AC-TRL",
                                "name": ["Trey Lipscomb"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-TS": {
                                "cardNumber": "AC-TS",
                                "name": ["Tyler Soderstrom"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "AC-VG": {
                                "cardNumber": "AC-VG",
                                "name": ["Vaughn Grissom"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "AC-VIS": {
                                "cardNumber": "AC-VIS",
                                "name": ["Victor Scott II"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-WA": {
                                "cardNumber": "AC-WA",
                                "name": ["Wilyer Abreu"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-WL": {
                                "cardNumber": "AC-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-WM": {
                                "cardNumber": "AC-WM",
                                "name": ["Wade Meckler"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-YD": {
                                "cardNumber": "AC-YD",
                                "name": ["Yainer Diaz"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "AC-YM": {
                                "cardNumber": "AC-YM",
                                "name": ["Yuki Matsui"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-YR": {
                                "cardNumber": "AC-YR",
                                "name": ["Yariel Rodríguez"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-YY": {
                                "cardNumber": "AC-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-ZD": {
                                "cardNumber": "AC-ZD",
                                "name": ["Zach DeLoach"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "AC-ZG": {
                                "cardNumber": "AC-ZG",
                                "name": ["Zack Gelof"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "AC-ZN": {
                                "cardNumber": "AC-ZN",
                                "name": ["Zach Neto"],
                                "team": ["Angels®"],
                                "cardAttributes": []
                            },
                            "RA-AM": {
                                "cardNumber": "RA-AM",
                                "name": ["Austin Martin"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AP": {
                                "cardNumber": "RA-AP",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AU": {
                                "cardNumber": "RA-AU",
                                "name": ["Abner Uribe"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-BB": {
                                "cardNumber": "RA-BB",
                                "name": ["Ben Brown"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-BRR": {
                                "cardNumber": "RA-BRR",
                                "name": ["Bryan Ramos"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-CBE": {
                                "cardNumber": "RA-CBE",
                                "name": ["Clayton Beeter"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-DHE": {
                                "cardNumber": "RA-DHE",
                                "name": ["Daysbel Hernandez"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-DR": {
                                "cardNumber": "RA-DR",
                                "name": ["Drew Rom"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-DS": {
                                "cardNumber": "RA-DS",
                                "name": ["Devin Sweet"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-ESH": {
                                "cardNumber": "RA-ESH",
                                "name": ["Emmet Sheehan"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-IL": {
                                "cardNumber": "RA-IL",
                                "name": ["Irving Lopez"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JBA": {
                                "cardNumber": "RA-JBA",
                                "name": ["Jordan Balazovic"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JBO": {
                                "cardNumber": "RA-JBO",
                                "name": ["Jorge Barrosa"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JBY": {
                                "cardNumber": "RA-JBY",
                                "name": ["Joey Boyle"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JJ": {
                                "cardNumber": "RA-JJ",
                                "name": ["Jared Jones"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JL": {
                                "cardNumber": "RA-JL",
                                "name": ["Jack Leiter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JLO": {
                                "cardNumber": "RA-JLO",
                                "name": ["Jacob Lopez"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JOF": {
                                "cardNumber": "RA-JOF",
                                "name": ["Jose Ferrer"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JR": {
                                "cardNumber": "RA-JR",
                                "name": ["Johnathan Rodriguez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JRO": {
                                "cardNumber": "RA-JRO",
                                "name": ["Johan Rojas"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-KI": {
                                "cardNumber": "RA-KI",
                                "name": ["Kolton Ingram"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-KMA": {
                                "cardNumber": "RA-KMA",
                                "name": ["Kyle Manzardo"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-KW": {
                                "cardNumber": "RA-KW",
                                "name": ["Keaton Winn"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-MWA": {
                                "cardNumber": "RA-MWA",
                                "name": ["Matt Waldron"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-PS": {
                                "cardNumber": "RA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-RG": {
                                "cardNumber": "RA-RG",
                                "name": ["Robert Gasser"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-RK": {
                                "cardNumber": "RA-RK",
                                "name": ["Ricky Karcher"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-SDU": {
                                "cardNumber": "RA-SDU",
                                "name": ["Shawn Dubin"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-TB": {
                                "cardNumber": "RA-TB",
                                "name": ["Tyler Black"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-VME": {
                                "cardNumber": "RA-VME",
                                "name": ["Victor Mederos"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-WP": {
                                "cardNumber": "RA-WP",
                                "name": ["Wenceel Pérez"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-YG": {
                                "cardNumber": "RA-YG",
                                "name": ["Yoendrys Gomez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Topps Chrome Rookie Lava Lamp Autographs": {
                            "RA-AP": {
                                "cardNumber": "RA-AP",
                                "name": ["Andy Pages"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-AW": {
                                "cardNumber": "RA-AW",
                                "name": ["Austin Wells"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-CC": {
                                "cardNumber": "RA-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-CKE": {
                                "cardNumber": "RA-CKE",
                                "name": ["Colt Keith"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-EC": {
                                "cardNumber": "RA-EC",
                                "name": ["Evan Carter"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-EDLC": {
                                "cardNumber": "RA-EDLC",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JC": {
                                "cardNumber": "RA-JC",
                                "name": ["Jackson Chourio"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JCA": {
                                "cardNumber": "RA-JCA",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JDZ": {
                                "cardNumber": "RA-JDZ",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JH": {
                                "cardNumber": "RA-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JHL": {
                                "cardNumber": "RA-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JLA": {
                                "cardNumber": "RA-JLA",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-JME": {
                                "cardNumber": "RA-JME",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-LBU": {
                                "cardNumber": "RA-LBU",
                                "name": ["Lawrence Butler"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-MW": {
                                "cardNumber": "RA-MW",
                                "name": ["Masyn Winn"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-NG": {
                                "cardNumber": "RA-NG",
                                "name": ["Nick Gonzales"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-NS": {
                                "cardNumber": "RA-NS",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-RM": {
                                "cardNumber": "RA-RM",
                                "name": ["Ronny Mauricio"],
                                "team": ["New York Mets®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-SF": {
                                "cardNumber": "RA-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-SI": {
                                "cardNumber": "RA-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-WL": {
                                "cardNumber": "RA-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "RA-YY": {
                                "cardNumber": "RA-YY",
                                "name": ["Yoshinobu Yamamoto"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Chrome Legend Autographs": {
                            "CLA-BL": {
                                "cardNumber": "CLA-BL",
                                "name": ["Barry Larkin"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": []
                            },
                            "CLA-CB": {
                                "cardNumber": "CLA-CB",
                                "name": ["Carlos Beltran"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "CLA-CF": {
                                "cardNumber": "CLA-CF",
                                "name": ["Carlton Fisk"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "CLA-CJ": {
                                "cardNumber": "CLA-CJ",
                                "name": ["Chipper Jones"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CLA-DM": {
                                "cardNumber": "CLA-DM",
                                "name": ["Dale Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CLA-OS": {
                                "cardNumber": "CLA-OS",
                                "name": ["Ozzie Smith"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CLA-RH": {
                                "cardNumber": "CLA-RH",
                                "name": ["Rickey Henderson"],
                                "team": ["Oakland Athletics®"],
                                "cardAttributes": []
                            },
                            "CLA-TH": {
                                "cardNumber": "CLA-TH",
                                "name": ["Trevor Hoffman"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CLA-WB": {
                                "cardNumber": "CLA-WB",
                                "name": ["Wade Boggs"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            }
                        },
                        "2024 All Star Game Autographs": {
                            "ASGCA-AR": {
                                "cardNumber": "ASGCA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASGCA-BR": {
                                "cardNumber": "ASGCA-BR",
                                "name": ["Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": []
                            },
                            "ASGCA-BW": {
                                "cardNumber": "ASGCA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASGCA-CA": {
                                "cardNumber": "ASGCA-CA",
                                "name": ["CJ Abrams"],
                                "team": ["Washington Nationals®"],
                                "cardAttributes": []
                            },
                            "ASGCA-CB": {
                                "cardNumber": "ASGCA-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "ASGCA-CH": {
                                "cardNumber": "ASGCA-CH",
                                "name": ["Clay Holmes"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASGCA-CR": {
                                "cardNumber": "ASGCA-CR",
                                "name": ["Cole Ragans"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASGCA-CY": {
                                "cardNumber": "ASGCA-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "ASGCA-DF": {
                                "cardNumber": "ASGCA-DF",
                                "name": ["David Fry"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASGCA-FT": {
                                "cardNumber": "ASGCA-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "ASGCA-GC": {
                                "cardNumber": "ASGCA-GC",
                                "name": ["Garrett Crochet"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "ASGCA-HR": {
                                "cardNumber": "ASGCA-HR",
                                "name": ["Heliot Ramos"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASGCA-JA": {
                                "cardNumber": "ASGCA-JA",
                                "name": ["Jose Altuve"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "ASGCA-JM": {
                                "cardNumber": "ASGCA-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGCA-JR": {
                                "cardNumber": "ASGCA-JR",
                                "name": ["José Ramírez"],
                                "team": ["Cleveland Guardians™"],
                                "cardAttributes": []
                            },
                            "ASGCA-JS": {
                                "cardNumber": "ASGCA-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "ASGCA-LW": {
                                "cardNumber": "ASGCA-LW",
                                "name": ["Logan Webb"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "ASGCA-MM": {
                                "cardNumber": "ASGCA-MM",
                                "name": ["Mason Miller"],
                                "team": ["Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "ASGCA-PA": {
                                "cardNumber": "ASGCA-PA",
                                "name": ["Pete Alonso"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "ASGCA-PS": {
                                "cardNumber": "ASGCA-PS",
                                "name": ["Paul Skenes"],
                                "team": ["Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGCA-SI": {
                                "cardNumber": "ASGCA-SI",
                                "name": ["Shota Imanaga"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "ASGCA-SL": {
                                "cardNumber": "ASGCA-SL",
                                "name": ["Seth Lugo"],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "ASGCA-TH": {
                                "cardNumber": "ASGCA-TH",
                                "name": ["Tanner Houck"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "ASGCA-VG": {
                                "cardNumber": "ASGCA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "ASGCA-WS": {
                                "cardNumber": "ASGCA-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "ASGCA-YA": {
                                "cardNumber": "ASGCA-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "1989 Topps Baseball Autographs": {
                            "89CA-BW": {
                                "cardNumber": "89CA-BW",
                                "name": ["Bobby Witt Jr."],
                                "team": ["Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "89CA-CB": {
                                "cardNumber": "89CA-CB",
                                "name": ["Corbin Burnes"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "89CA-CY": {
                                "cardNumber": "89CA-CY",
                                "name": ["Christian Yelich"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": []
                            },
                            "89CA-DS": {
                                "cardNumber": "89CA-DS",
                                "name": ["Dansby Swanson"],
                                "team": ["Chicago Cubs®"],
                                "cardAttributes": []
                            },
                            "89CA-GS": {
                                "cardNumber": "89CA-GS",
                                "name": ["George Springer"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89CA-JA": {
                                "cardNumber": "89CA-JA",
                                "name": ["José Abreu"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            },
                            "89CA-JM": {
                                "cardNumber": "89CA-JM",
                                "name": ["J.D. Martinez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "89CA-JR": {
                                "cardNumber": "89CA-JR",
                                "name": ["Julio Rodríguez"],
                                "team": ["Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "89CA-JV": {
                                "cardNumber": "89CA-JV",
                                "name": ["Joey Votto"],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89CA-KB": {
                                "cardNumber": "89CA-KB",
                                "name": ["Kris Bryant"],
                                "team": ["Colorado Rockies™"],
                                "cardAttributes": []
                            },
                            "89CA-LR": {
                                "cardNumber": "89CA-LR",
                                "name": ["Luis Robert Jr."],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "89CA-MM": {
                                "cardNumber": "89CA-MM",
                                "name": ["Manny Machado"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "89CA-MO": {
                                "cardNumber": "89CA-MO",
                                "name": ["Matt Olson"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89CA-NA": {
                                "cardNumber": "89CA-NA",
                                "name": ["Nolan Arenado"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "89CA-RA": {
                                "cardNumber": "89CA-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89CA-RD": {
                                "cardNumber": "89CA-RD",
                                "name": ["Rafael Devers"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "89CA-SM": {
                                "cardNumber": "89CA-SM",
                                "name": ["Sean Murphy"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "89CA-ST": {
                                "cardNumber": "89CA-ST",
                                "name": ["Spencer Torkelson"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            },
                            "89CA-TT": {
                                "cardNumber": "89CA-TT",
                                "name": ["Trea Turner"],
                                "team": ["Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "89CA-VG": {
                                "cardNumber": "89CA-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            },
                            "89CA-WS": {
                                "cardNumber": "89CA-WS",
                                "name": ["Will Smith"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "89CA-YA": {
                                "cardNumber": "89CA-YA",
                                "name": ["Yordan Alvarez"],
                                "team": ["Houston Astros®"],
                                "cardAttributes": []
                            }
                        },
                        "Future Stars Autographs": {
                            "FSA-AR": {
                                "cardNumber": "FSA-AR",
                                "name": ["Adley Rutschman"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FSA-AV": {
                                "cardNumber": "FSA-AV",
                                "name": ["Anthony Volpe"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FSA-BB": {
                                "cardNumber": "FSA-BB",
                                "name": ["Brett Baty"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "FSA-CC": {
                                "cardNumber": "FSA-CC",
                                "name": ["Corbin Carroll"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "FSA-FA": {
                                "cardNumber": "FSA-FA",
                                "name": ["Francisco Alvarez"],
                                "team": ["New York Mets®"],
                                "cardAttributes": []
                            },
                            "FSA-GM": {
                                "cardNumber": "FSA-GM",
                                "name": ["Gabriel Moreno"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "FSA-GR": {
                                "cardNumber": "FSA-GR",
                                "name": ["Grayson Rodriguez"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": []
                            },
                            "FSA-JJ": {
                                "cardNumber": "FSA-JJ",
                                "name": ["Josh Jung"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "FSA-JO": {
                                "cardNumber": "FSA-JO",
                                "name": ["James Outman"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "FSA-MH": {
                                "cardNumber": "FSA-MH",
                                "name": ["Michael Harris II"],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "FSA-MV": {
                                "cardNumber": "FSA-MV",
                                "name": ["Miguel Vargas"],
                                "team": ["Chicago White Sox®"],
                                "cardAttributes": []
                            },
                            "FSA-MY": {
                                "cardNumber": "FSA-MY",
                                "name": ["Masataka Yoshida"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "FSA-OC": {
                                "cardNumber": "FSA-OC",
                                "name": ["Oswaldo Cabrera"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FSA-OP": {
                                "cardNumber": "FSA-OP",
                                "name": ["Oswald Peraza"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "FSA-RG": {
                                "cardNumber": "FSA-RG",
                                "name": ["Riley Greene"],
                                "team": ["Detroit Tigers®"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome All Etch Autograph Variation": {
                            "CUAE-BB": {
                                "cardNumber": "CUAE-BB",
                                "name": ["Byron Buxton"],
                                "team": ["Minnesota Twins®"],
                                "cardAttributes": []
                            },
                            "CUAE-CK": {
                                "cardNumber": "CUAE-CK",
                                "name": ["Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CUAE-FT": {
                                "cardNumber": "CUAE-FT",
                                "name": ["Fernando Tatis Jr."],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CUAE-JS": {
                                "cardNumber": "CUAE-JS",
                                "name": ["Juan Soto"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CUAE-JW": {
                                "cardNumber": "CUAE-JW",
                                "name": ["Jordan Walker"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CUAE-PG": {
                                "cardNumber": "CUAE-PG",
                                "name": ["Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CUAE-RA": {
                                "cardNumber": "CUAE-RA",
                                "name": ["Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CUAE-VG": {
                                "cardNumber": "CUAE-VG",
                                "name": ["Vladimir Guerrero Jr."],
                                "team": ["Toronto Blue Jays®"],
                                "cardAttributes": []
                            }
                        },
                        "Chrome All Etch Rookie Rush Autograph Variation": {
                            "CURR-CC": {
                                "cardNumber": "CURR-CC",
                                "name": ["Colton Cowser"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-CM": {
                                "cardNumber": "CURR-CM",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-CR": {
                                "cardNumber": "CURR-CR",
                                "name": ["Ceddanne Rafaela"],
                                "team": ["Boston Red Sox®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-ED": {
                                "cardNumber": "CURR-ED",
                                "name": ["Elly De La Cruz"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-HK": {
                                "cardNumber": "CURR-HK",
                                "name": ["Heston Kjerstad"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-JC": {
                                "cardNumber": "CURR-JC",
                                "name": ["Junior Caminero"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-JD": {
                                "cardNumber": "CURR-JD",
                                "name": ["Jasson Domínguez"],
                                "team": ["New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-JH": {
                                "cardNumber": "CURR-JH",
                                "name": ["Jackson Holliday"],
                                "team": ["Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-JHL": {
                                "cardNumber": "CURR-JHL",
                                "name": ["Jung Hoo Lee"],
                                "team": ["San Francisco Giants®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-JL": {
                                "cardNumber": "CURR-JL",
                                "name": ["Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-JM": {
                                "cardNumber": "CURR-JM",
                                "name": ["Jackson Merrill"],
                                "team": ["San Diego Padres™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-NM": {
                                "cardNumber": "CURR-NM",
                                "name": ["Noelvi Marte"],
                                "team": ["Cincinnati Reds®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-NS": {
                                "cardNumber": "CURR-NS",
                                "name": ["Nolan Schanuel"],
                                "team": ["Angels®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-SF": {
                                "cardNumber": "CURR-SF",
                                "name": ["Sal Frelick"],
                                "team": ["Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CURR-WL": {
                                "cardNumber": "CURR-WL",
                                "name": ["Wyatt Langford"],
                                "team": ["Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Dual Autograph Cards": {
                            "CDA-AJO": {
                                "cardNumber": "CDA-AJO",
                                "name": ["Matt Olson", "Ronald Acuña Jr."],
                                "team": ["Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CDA-ALP": {
                                "cardNumber": "CDA-ALP",
                                "name": ["Francisco Alvarez", "Mike Piazza"],
                                "team": ["New York Mets®", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "CDA-CAL": {
                                "cardNumber": "CDA-CAL",
                                "name": ["Corbin Carroll", "Jordan Lawlar"],
                                "team": ["Arizona Diamondbacks®", "Arizona Diamondbacks®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CDA-CLA": {
                                "cardNumber": "CDA-CLA",
                                "name": ["Evan Carter", "Wyatt Langford"],
                                "team": ["Texas Rangers®", "Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CDA-FRC": {
                                "cardNumber": "CDA-FRC",
                                "name": ["Sal Frelick", "Jackson Chourio"],
                                "team": ["Milwaukee Brewers™", "Milwaukee Brewers™"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CDA-IRO": {
                                "cardNumber": "CDA-IRO",
                                "name": ["Ichiro", "Julio Rodríguez"],
                                "team": ["Seattle Mariners™", "Seattle Mariners™"],
                                "cardAttributes": []
                            },
                            "CDA-JEV": {
                                "cardNumber": "CDA-JEV",
                                "name": ["Anthony Volpe", "Derek Jeter"],
                                "team": ["New York Yankees®", "New York Yankees®"],
                                "cardAttributes": []
                            },
                            "CDA-KKE": {
                                "cardNumber": "CDA-KKE",
                                "name": ["Sandy Koufax", "Clayton Kershaw"],
                                "team": ["Los Angeles Dodgers®", "Los Angeles Dodgers®"],
                                "cardAttributes": []
                            },
                            "CDA-RIH": {
                                "cardNumber": "CDA-RIH",
                                "name": ["Bill Ripken", "Jackson Holliday"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CDA-RJH": {
                                "cardNumber": "CDA-RJH",
                                "name": ["Jackson Holliday", "Cal Ripken Jr."],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CDA-TJM": {
                                "cardNumber": "CDA-TJM",
                                "name": ["Manny Machado", "Fernando Tatis Jr."],
                                "team": ["San Diego Padres™", "San Diego Padres™"],
                                "cardAttributes": []
                            },
                            "CDA-VLE": {
                                "cardNumber": "CDA-VLE",
                                "name": ["Jack Leiter", "Anthony Volpe"],
                                "team": ["Texas Rangers®", "New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            }
                        },
                        "Triple Autograph Cards": {
                            "CTA-AAB": {
                                "cardNumber": "CTA-AAB",
                                "name": ["Yordan Alvarez", "Jose Altuve", "Alex Bregman"],
                                "team": ["Houston Astros®", "Houston Astros®", "Houston Astros®"],
                                "cardAttributes": []
                            },
                            "CTA-BJB": {
                                "cardNumber": "CTA-BJB",
                                "name": ["Wade Boggs", "Chipper Jones", "George Brett"],
                                "team": ["Boston Red Sox®", "Atlanta Braves™", "Kansas City Royals®"],
                                "cardAttributes": []
                            },
                            "CTA-BPM": {
                                "cardNumber": "CTA-BPM",
                                "name": ["Yadier Molina", "Johnny Bench", "Buster Posey"],
                                "team": ["St. Louis Cardinals®", "Cincinnati Reds®", "San Francisco Giants®"],
                                "cardAttributes": []
                            },
                            "CTA-CCL": {
                                "cardNumber": "CTA-CCL",
                                "name": ["Pete Crow-Armstrong", "Colton Cowser", "Wyatt Langford"],
                                "team": ["Chicago Cubs®", "Baltimore Orioles®", "Texas Rangers®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTA-CWT": {
                                "cardNumber": "CTA-CWT",
                                "name": ["Bobby Witt Jr.", "Trea Turner", "Corbin Carroll"],
                                "team": ["Kansas City Royals®", "Philadelphia Phillies®", "Arizona Diamondbacks®"],
                                "cardAttributes": []
                            },
                            "CTA-HCM": {
                                "cardNumber": "CTA-HCM",
                                "name": ["Jackson Chourio", "Jackson Merrill", "Jackson Holliday"],
                                "team": ["Milwaukee Brewers™", "San Diego Padres™", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTA-HMJ": {
                                "cardNumber": "CTA-HMJ",
                                "name": ["Reggie Jackson", "Rickey Henderson", "Mark McGwire"],
                                "team": ["Oakland Athletics™", "Oakland Athletics™", "Oakland Athletics™"],
                                "cardAttributes": []
                            },
                            "CTA-HRH": {
                                "cardNumber": "CTA-HRH",
                                "name": ["Jackson Holliday", "Adley Rutschman", "Gunnar Henderson"],
                                "team": ["Baltimore Orioles®", "Baltimore Orioles®", "Baltimore Orioles®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTA-JSD": {
                                "cardNumber": "CTA-JSD",
                                "name": ["Jasson Domínguez", "Aaron Judge", "Juan Soto"],
                                "team": ["New York Yankees®", "New York Yankees®", "New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTA-MOD": {
                                "cardNumber": "CTA-MOD",
                                "name": ["Pedro Martinez", "Rafael Devers", "David Ortiz"],
                                "team": ["Boston Red Sox®", "Boston Red Sox®", "Boston Red Sox®"],
                                "cardAttributes": []
                            },
                            "CTA-MSG": {
                                "cardNumber": "CTA-MSG",
                                "name": ["Tom Glavine", "John Smoltz", "Greg Maddux"],
                                "team": ["Atlanta Braves™", "Atlanta Braves™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CTA-PMG": {
                                "cardNumber": "CTA-PMG",
                                "name": ["Albert Pujols", "Mark McGwire", "Paul Goldschmidt"],
                                "team": ["St. Louis Cardinals®", "St. Louis Cardinals®", "St. Louis Cardinals®"],
                                "cardAttributes": []
                            },
                            "CTA-PRA": {
                                "cardNumber": "CTA-PRA",
                                "name": ["Mike Piazza", "Pete Alonso", "David Wright"],
                                "team": ["New York Mets®", "New York Mets®", "New York Mets®"],
                                "cardAttributes": []
                            },
                            "CTA-SJR": {
                                "cardNumber": "CTA-SJR",
                                "name": ["Paul Skenes", "Jared Jones", "Bryan Reynolds"],
                                "team": ["Pittsburgh Pirates®", "Pittsburgh Pirates®", "Pittsburgh Pirates®"],
                                "cardAttributes": ["Rookie"]
                            },
                            "CTA-SSJ": {
                                "cardNumber": "CTA-SSJ",
                                "name": ["Marcus Semien", "Corey Seager", "Josh Jung"],
                                "team": ["Texas Rangers®", "Texas Rangers®", "Texas Rangers®"],
                                "cardAttributes": []
                            },
                            "CTA-TAG": {
                                "cardNumber": "CTA-TAG",
                                "name": ["Vladimir Guerrero Jr.", "Fernando Tatis Jr.", "Ronald Acuña Jr."],
                                "team": ["Toronto Blue Jays®", "San Diego Padres™", "Atlanta Braves™"],
                                "cardAttributes": []
                            },
                            "CTA-URH": {
                                "cardNumber": "CTA-URH",
                                "name": ["Ryan Howard", "Jimmy Rollins", "Chase Utley"],
                                "team": ["Philadelphia Phillies®", "Philadelphia Phillies®", "Philadelphia Phillies®"],
                                "cardAttributes": []
                            },
                            "CTA-WVD": {
                                "cardNumber": "CTA-WVD",
                                "name": ["Elly De La Cruz", "Bobby Witt Jr.", "Anthony Volpe"],
                                "team": ["Cincinnati Reds®", "Kansas City Royals®", "New York Yankees®"],
                                "cardAttributes": ["Rookie"]
                            }
                        }
                    }
                }
            }
        }
    }
}

export const MLB_2024_TOPPS_CHROME_UPDATE_SERIES_PARALLELS = {
    "MLB": {
        "2024": {
            "Topps": {
                "Topps Chrome® Baseball Update Series": {
                    "Base Card Set": {
                        "Base": [
                            { "name": "REFRACTOR", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "X-FRACTOR", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "RAYWAVE", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "PRISM REFRACTOR", "color": "#FFFFFF", "limitedTo": null },
                            { "name": "NEGATIVE REFRACTOR", "color": "#000000", "limitedTo": null },
                            { "name": "MAGENTA REFRACTOR", "color": "#FF00FF", "limitedTo": 399 },
                            { "name": "MAGENTA/PURPLE LAVA LAMP REFRACTOR", "color": "#C000C0", "limitedTo": 299 },
                            { "name": "PURPLE REFRACTOR", "color": "#800080", "limitedTo": 250 },
                            { "name": "AQUA", "color": "#00FFDF", "limitedTo": 199 },
                            { "name": "AQUA RAYWAVE", "color": "#00FFDF", "limitedTo": 199 },
                            { "name": "AQUA/BLUE LAVA LAMP REFRACTOR", "color": "#0FC8EF", "limitedTo": 175 },
                            { "name": "BLUE RAYWAVE", "color": "#1E90FF", "limitedTo": 150 },
                            { "name": "BLUE/GREEN LAVA LAMP REFRACTOR", "color": "#0F8880", "limitedTo": 125 },
                            { "name": "GREEN", "color": "#008000", "limitedTo": 99 },
                            { "name": "GREEN RAYWAVE", "color": "#008000", "limitedTo": 99 },
                            { "name": "GREEN WAVE", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "GOLD RAYWAVE", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "GOLD MINI DIAMONDS REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "ORANGE RAYWAVE", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "ORANGE/BLACK LAVA LAMP REFRACTOR", "color": "#FFA500", "limitedTo": 15 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "BLACK RAYWAVE", "color": "#000000", "limitedTo": 10 },
                            { "name": "FROZENFRACTOR", "color": "#A2D2DF", "limitedTo": 5 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "RED RAYWAVE", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PRINTING PLATE (BLACK)", "color": "#000000", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (CYAN)", "color": "#00FFFF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (MAGENTA)", "color": "#FF00FF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (YELLOW)", "color": "#EBD834", "limitedTo": 1 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Base Cards Image Variation": [
                            { "name": "GREEN SPECKLE REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD SPECKLE REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE SPECKLE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK SPECKLE REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED SPECKLE REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ]
                    },
                    "Insert": {
                        "2024 All Star Game Chrome": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Stratospheric Stars": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "2024 Topps Chrome Youthquake": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Future Stars": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Chrome All Etch": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Chrome All Etch Rookie Rush": [
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Celebración": [
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Crushed": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Diamond Dominance": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Lets Go! Chrome": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Topps Chrome Expose": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Home Field Advantage": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Topps Chrome Radiating Rookies": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Helix": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Major League Minis": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Sizzle": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "MLB Illustrations": [
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ]
                    },
                    "Autograph": {
                        "Topps Chrome Update Autograph Cards": [
                            { "name": "REFRACTOR", "color": "#FFFFFF", "limitedTo": 499 },
                            { "name": "PURPLE SPECKLE REFRACTOR", "color": "#800080", "limitedTo": 299 },
                            { "name": "PURPLE REFRACTOR", "color": "#800080", "limitedTo": 250 },
                            { "name": "AQUA WAVE REFRACTOR", "color": "#00FFDF", "limitedTo": 199 },
                            { "name": "BLUE REFRACTOR", "color": "#1E90FF", "limitedTo": 150 },
                            { "name": "BLUE RAYWAVE REFRACTOR", "color": "#1E90FF", "limitedTo": 150 },
                            { "name": "GREEN REFRACTOR", "color": "#008000", "limitedTo": 99 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "GOLD WAVE REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "ORANGE WAVE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "B&W MINI-DIAMOND REFRACTOR", "color": "#000000", "limitedTo": null },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "RED WAVE REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PRINTING PLATE (BLACK)", "color": "#000000", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (CYAN)", "color": "#00FFFF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (MAGENTA)", "color": "#FF00FF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (YELLOW)", "color": "#EBD834", "limitedTo": 1 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Topps Chrome Rookie Lava Lamp Autographs": [
                            { "name": "AQUA/BLUE", "color": "#0FC8EF", "limitedTo": 175 },
                            { "name": "BLUE/GREEN", "color": "#0F8880", "limitedTo": 125 },
                            { "name": "GREEN/YELLOW", "color": "#76AC1A", "limitedTo": 85 },
                            { "name": "GOLD/ORANGE", "color": "#FFBE00", "limitedTo": 35 },
                            { "name": "ORANGE/BLACK", "color": "#FFA500", "limitedTo": 15 },
                            { "name": "BLACK/RED", "color": "#800000", "limitedTo": 8 }
                        ],
                        "Chrome Legend Autographs": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "PRINTING PLATE (BLACK)", "color": "#000000", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (CYAN)", "color": "#00FFFF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (MAGENTA)", "color": "#FF00FF", "limitedTo": 1 },
                            { "name": "PRINTING PLATE (YELLOW)", "color": "#EBD834", "limitedTo": 1 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "2024 All Star Game Autographs": [
                            { "name": "X-FRACTOR", "color": "#FFFFFF", "limitedTo": 125 },
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "1989 Topps Baseball Autographs": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Future Stars Autographs": [
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Chrome All Etch Autograph Variation": [
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Chrome All Etch Rookie Rush Autograph Variation": [
                            { "name": "GOLD REFRACTOR", "color": "#FFD700", "limitedTo": 50 },
                            { "name": "ORANGE REFRACTOR", "color": "#FFA500", "limitedTo": 25 },
                            { "name": "BLACK REFRACTOR", "color": "#000000", "limitedTo": 10 },
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Dual Autograph Cards": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ],
                        "Triple Autograph Cards": [
                            { "name": "RED REFRACTOR", "color": "#FF0000", "limitedTo": 5 },
                            { "name": "SUPERFRACTOR", "color": "#807955", "limitedTo": 1 }
                        ]
                    }
                }
            }
        }
    }
}