import PropTypes from 'prop-types';

// styles
import {
    CardDisplayLargeContainer,
    ImgContainer,
    LeftOverlayContent,
    RightOverlayContent
} from './CardDisplayLarge.styles';

// assets
import pullVaultIcon from '../../../../assets/system/cardvault-symbol.png';

// components
import CardAttribute from '../../../../components/card-attribute/CardAttribute';

export default function CardDisplayLarge({ cardData }) {
    const {
        name = [],
        team = [],
        cardNumber = "",
        cardAttributes = [],
        cardParallel = {}
    } = cardData || {}

    return (
        <CardDisplayLargeContainer
            whileHover={{ scale: 1.025 }}
            transition={{ type: "spring", stiffness: 240, damping: 20 }}
        >
            <LeftOverlayContent>
                {name.map((playerName) => {
                    return <h1 key={playerName}>{playerName.toUpperCase()}</h1>
                })}
                {team.map((teamName) => {
                    return <CardAttribute key={teamName} size='L' text={teamName} />
                })}
            </LeftOverlayContent>
            <ImgContainer>
                <img src={pullVaultIcon} alt="no card" />
            </ImgContainer>
            <RightOverlayContent>
                <CardAttribute text={cardNumber} size='L' />
                {cardAttributes.length !== 0 &&
                    <CardAttribute text={cardAttributes} size='L' />
                }
                <CardAttribute color={cardParallel.cardColor} text={cardParallel.cardPrintNumber ? `${cardParallel.cardParallelName} (${cardParallel.cardPrintNumber}/${cardParallel.cardSupply})` : cardParallel.cardParallelName} size={'L'} />
            </RightOverlayContent>
        </CardDisplayLargeContainer>
    )
}

CardDisplayLarge.propTypes = {
    cardData: PropTypes.object.isRequired
};
