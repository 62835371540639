import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { selectIsViewingNewSelection } from '../../../../store/showcase/showcase.selector';
import { setNewSelectionCardData } from '../../../../store/showcase/showcase.action';

// styles
import {
    NewCardSelectionContainer,
    ContentContainer,
    PanelRowTitle,
    OutletContainer
} from './NewCardSelection.styles';

// components
import Title from '../../../../components/widgets/components/title/Title';
import TertiaryBtn from '../../../../components/btns/tertiary-btn/TertiaryBtn';

export default function NewCardSelection() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isViewingNewSelection = useSelector(selectIsViewingNewSelection);
    const { sport, year, manufacturer, series, cardType, cardTypeCategory } = useParams();

    useEffect(() => {
        if (!sport) {
            const newSelectionCardData = {
                name: [],
                cardPath: [],
                cardParallel: {
                    cardColor: '',
                    cardParallelName: '',
                    cardPrintNumber: null,
                    cardSupply: 0,
                }
            };
            dispatch(setNewSelectionCardData(newSelectionCardData));
        }
    }, [dispatch, sport])

    const handleNavigate = (level) => {
        let path = '/collection';

        switch (level) {
            case 'sport':
                path = '/showcase-add';
                break;
            case 'year':
                path = `/showcase-add/${sport}`;
                break;
            case 'manufacturer':
                path = `/showcase-add/${sport}/${year}`;
                break;
            case 'series':
                path = `/showcase-add/${sport}/${year}/${manufacturer}`;
                break;
            case 'cardType':
                path = `/showcase-add/${sport}/${year}/${manufacturer}/${series}`;
                break;
            case 'cardTypeCategory':
                path = `/showcase-add/${sport}/${year}/${manufacturer}/${series}/${cardType}`;
                break;
            default:
                break;
        }
        navigate(path);
    };
    return (
        <NewCardSelectionContainer>
            <ContentContainer $isViewingNewSelection={isViewingNewSelection}>
                {!isViewingNewSelection &&
                    <Title text={"SELECT CARD TO ADD"} />
                }
                {!isViewingNewSelection &&
                    <div style={{ marginBottom: "24px" }}>
                        <TertiaryBtn route={"/"} title={"BACK TO SHOWCASE"} />
                    </div>
                }

                {sport && !isViewingNewSelection &&
                    <PanelRowTitle>
                        {sport && (
                            <h4 onClick={() => handleNavigate('sport')}>{sport}</h4>
                        )}
                        {year && (
                            <h4 onClick={() => handleNavigate('year')}>{year}</h4>
                        )}
                        {manufacturer && (
                            <h4 onClick={() => handleNavigate('manufacturer')}>{manufacturer}</h4>
                        )}
                        {series && (
                            <h4 onClick={() => handleNavigate('series')}>{series}</h4>
                        )}
                        {cardType && (
                            <h4 onClick={() => handleNavigate('cardType')}>{cardType}</h4>
                        )}
                        {cardTypeCategory && (
                            <h4 onClick={() => handleNavigate('cardTypeCategory')}>{cardTypeCategory}</h4>
                        )}
                    </PanelRowTitle>
                }
                <OutletContainer>
                    <Outlet />
                </OutletContainer>
            </ContentContainer>
        </NewCardSelectionContainer>
    )
}
