// styles
import {
    SecondaryTitleContainer
} from './SecondaryTitle.styles'

export default function SecondaryTitle({ text }) {
  return (
    <SecondaryTitleContainer>
        <h2>{text}</h2>
    </SecondaryTitleContainer>
  )
}
