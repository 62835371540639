import styled from "styled-components";

export const CardOptionsContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
    left: 16px;
    overflow: visible;
    position: absolute;
    top: 16px;
    width: calc(100% - 16px);
`
export const LeftOptions = styled.div`
    display: flex;
    gap: 16px;
`