import { SHOWCASE_ACTION_TYPES } from "./showcase.types.js";

const SHOWCASE_INITIAL_STATE = {
    preSelectedCardId: null,
    preSelectedCardType: null,
    selectedCardId: null,
    isViewingNewSelection: false,
    isEditingCard: false,
    isAddingCardImgs: false,
    cardImgsWereAdded: false,
    newSelectionCardData: {
        name: [],
        cardPath: [],
        cardParallel: {
            cardColor: '',
            cardParallelName: '',
            cardPrintNumber: null,
            cardSupply: 0,
        }
    },
    dreamTeamCardData_MLB: {}
};

export const showcaseReducer = (state = SHOWCASE_INITIAL_STATE, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_ID:
            return { ...state, preSelectedCardId: payload };
        case SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_TYPE:
            return { ...state, preSelectedCardType: payload };
        case SHOWCASE_ACTION_TYPES.SET_SELECTED_CARD_ID:
            return { ...state, selectedCardId: payload };
        case SHOWCASE_ACTION_TYPES.SET_NEW_SELECTION_CARD_DATA:
            return { ...state, newSelectionCardData: payload };
        case SHOWCASE_ACTION_TYPES.SET_IS_VIEWING_NEW_SELECTION:
            return { ...state, isViewingNewSelection: payload };
        case SHOWCASE_ACTION_TYPES.SET_IS_EDITING_CARD:
            return { ...state, isEditingCard: payload };
        case SHOWCASE_ACTION_TYPES.SET_IS_ADDING_CARD_IMGS:
            return { ...state, isAddingCardImgs: payload };
        case SHOWCASE_ACTION_TYPES.SET_CARD_IMGS_WERE_ADDED:
            return { ...state, cardImgsWereAdded: payload };
        case SHOWCASE_ACTION_TYPES.SET_DREAM_TEAM_MLB:
            return { ...state, dreamTeamCardData_MLB: payload };
        case SHOWCASE_ACTION_TYPES.UPDATE_DREAM_TEAM_POSITION:
            const { positionId, updatedData } = action.payload;
            return {
                ...state,
                dreamTeamCardData_MLB: {
                    ...state.dreamTeamCardData_MLB,
                    [positionId]: updatedData, // Update only the specific position
                },
            };
        default:
            return state;
    }
};