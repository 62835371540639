import { useState } from "react";

// styles
import { InfoIconImg } from './InfoIcon.styles';

// assets
import infoIcon from '../../../../assets/system/icons/info-icon-inactive.png';
import infoIconHover from '../../../../assets/system/icons/info-icon-active.png';

export default function InfoIcon() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <InfoIconImg
          src={isHovered ? infoIconHover : infoIcon}
          alt="click for more info"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      );
}
