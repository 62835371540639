import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectDreamTeamCardData_MLB, selectPreSelectedCardId } from '../../../../store/showcase/showcase.selector';
import { setSelectedCardId, setPreselectedCardType, setPreselectedCardId } from '../../../../store/showcase/showcase.action';
import { removeShowcaseCard } from '../../../../store/showcase/showcase.action';
import { fadeInUp } from '../../../../animations/Animations';
import { updateFirestorePositionData } from '../../../../utils/firebase/firebase.utils';

// styles
import {
    CardOptionsContainer,
    BtnContainer
} from './CardOptions.styles';

// components
import PrimaryBtn from '../../../../components/btns/primary-btn/PrimaryBtn';
import { selectUser } from '../../../../store/user/user.selector';


export default function CardOptions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const preSelectedCardId = useSelector(selectPreSelectedCardId);
    const dreamTeamCardData_MLB = useSelector(selectDreamTeamCardData_MLB);
    const positionHasCard = !!dreamTeamCardData_MLB?.[preSelectedCardId];

    const handleCardSelection = (id) => {
        dispatch(setSelectedCardId(id));
        dispatch(setPreselectedCardType(null));
        dispatch(setPreselectedCardId(null));
    }
    const handlePreSelectionCancel = () => {
        dispatch(setPreselectedCardType(null));
        dispatch(setPreselectedCardId(null));
    }
    const handleRemove = () => {
        // remove from Firestore
        updateFirestorePositionData(user.uid, "dreamTeamCardData_MLB", preSelectedCardId, null)
        // remove from Redux
        dispatch(removeShowcaseCard(dreamTeamCardData_MLB, preSelectedCardId));
    }

    return (
        <CardOptionsContainer>
            {positionHasCard ?
                <>
                    <BtnContainer
                        onClick={() => handleCardSelection(preSelectedCardId)}
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                        custom={0 * 0.05}
                    >
                        <PrimaryBtn title={"VIEW"} />
                    </BtnContainer>
                    <BtnContainer
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                        custom={1 * 0.05}
                    >
                        <PrimaryBtn title={"CHANGE POSITION"} />
                    </BtnContainer>
                    <BtnContainer
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                        custom={2 * 0.05}
                    >
                        <PrimaryBtn title={"REPLACE CARD"} />
                    </BtnContainer>
                    <BtnContainer
                        onClick={handleRemove}
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                        custom={3 * 0.05}
                    >
                        <PrimaryBtn title={"REMOVE"} />
                    </BtnContainer>
                </>
                :
                <>
                    <BtnContainer
                        onClick={() => navigate("/showcase-add")}
                        initial="hidden"
                        animate="visible"
                        variants={fadeInUp}
                        custom={3 * 0.05}
                    >
                        <PrimaryBtn title={"ADD CARD"} />
                    </BtnContainer>

                </>
            }
            <BtnContainer
                onClick={handlePreSelectionCancel}
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                custom={4 * 0.05}
            >
                <PrimaryBtn title={"BACK"} />
            </BtnContainer>
        </CardOptionsContainer>
    )
}
