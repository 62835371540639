import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectQueuedCardsDisplay } from '../../store/card/card.selector.js';

// Styles
import {
  AddCardPageContainer,
  ContentContainer,
  OutletContainer,
  PanelRowContainer,
  PanelRowTitle,
  QueuedCardsContainer
} from './AddCardPage.styles.js';

// components
import Title from '../../components/widgets/components/title/Title.js';
import QueuedCards from '../../components/queued-cards/QueuedCards.js';
import HeaderWidget from '../../components/widgets/header-widget/HeaderWidget.js';
import Subtitle from '../../components/widgets/components/subtitle/Subtitle.js';

export default function AddCardPage() {
  const navigate = useNavigate();
  const { sport, year, manufacturer, series, cardType, cardTypeCategory } = useParams();
  const [isFixed, setIsFixed] = useState(false);
  const queuedCardsDisplay = useSelector(selectQueuedCardsDisplay);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 93) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigate = (level) => {
    let path = '/add';

    switch (level) {
      case 'sport':
        path = '/add';
        break;
      case 'year':
        path = `/add/${sport}`;
        break;
      case 'manufacturer':
        path = `/add/${sport}/${year}`;
        break;
      case 'series':
        path = `/add/${sport}/${year}/${manufacturer}`;
        break;
      case 'cardType':
        path = `/add/${sport}/${year}/${manufacturer}/${series}`;
        break;
      case 'cardTypeCategory':
        path = `/add/${sport}/${year}/${manufacturer}/${series}/${cardType}`;
        break;
      default:
        break;
    }
    navigate(path);
  };

  return (
    <AddCardPageContainer>
      <HeaderWidget>
        <Title text={"ADD NEW CARD(S)"} />
        <Subtitle text={"42 Available Checklists"} infoKeyIsDisplayed={true} />
      </HeaderWidget>
      <ContentContainer>
        <PanelRowContainer>
          {sport &&
            <PanelRowTitle>
              {sport && (
                <h4 onClick={() => handleNavigate('sport')}>{sport}</h4>
              )}
              {year && (
                <h4 onClick={() => handleNavigate('year')}>{year}</h4>
              )}
              {manufacturer && (
                <h4 onClick={() => handleNavigate('manufacturer')}>{manufacturer}</h4>
              )}
              {series && (
                <h4 onClick={() => handleNavigate('series')}>{series}</h4>
              )}
              {cardType && (
                <h4 onClick={() => handleNavigate('cardType')}>{cardType}</h4>
              )}
              {cardTypeCategory && (
                <h4 onClick={() => handleNavigate('cardTypeCategory')}>{cardTypeCategory}</h4>
              )}
            </PanelRowTitle>
          }
          <OutletContainer $width={queuedCardsDisplay.length !== 0 ? '60%' : '100%'}>
            <Outlet />
          </OutletContainer>

        </PanelRowContainer>
        {queuedCardsDisplay.length !== 0 &&
          <QueuedCardsContainer $fixed={isFixed}>
            <QueuedCards />
          </QueuedCardsContainer>
        }

      </ContentContainer>

    </AddCardPageContainer>
  );
}