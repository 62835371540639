import styled from "styled-components";

export const SecondaryTitleContainer = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    width: 100%;

    h2 {
        font-family: 'Roboto Condensed';
        font-size: 24px;
        line-height: 24px;
        opacity: 0.5;
    }
`